<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-product">
    <v-container fluid>
      <!-- Header -->
      <v-row class="align-center mb-1 header-container">
        <v-col cols="12" md="12">
          <h1 class="title-topo">Lista de Produtos </h1>
          <v-switch
            color="amber darken-4"
            @change="setList()"
            v-model="showInactive"
            label="Mostrar Inativos"
            inset
          ></v-switch>
        </v-col>
      </v-row>

      <!-- Content -->
      <v-row>
        <!-- Filter Form -->
        <v-col cols="12" class="mb-4">
          <filter-form :list="list" />
        </v-col>

        <!-- List for Mobile -->
        <v-col cols="12" class="d-block d-md-none">
          <list-iterator :list="list" />
        </v-col>

        <!-- Table for Desktop -->
        <v-col cols="12" class="d-none d-md-block">
          <list-table :list="list" />
        </v-col>
      </v-row>
    </v-container>
  </admin-blade>
</template>


<script>
import AdminBlade from "@//Layouts/Blade";
import FilterForm from "@/views/ProductSession/Pages/Product/filterForm.vue";
import ListIterator from "@/views/ProductSession/Pages/Product/iterator.vue";
import ListTable from "@/views/ProductSession/Pages/Product/table.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
    FilterForm,
    ListIterator,
    ListTable,
  },
  computed: {
    showInactive: {
      get() {
        return this.$store.state.Product.filter.showInactive;
      },
      set(value) {
        this.$store.commit("Product/SET_SHOWINACTIVE", value);
      },
    },
    ...mapState("Product", ["list"]),
  },
  methods: {
    ...mapActions("Product", ["setList"]),
  },
};
</script>

<style>
.header-container {
}

.title-topo {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  border-bottom: 1px solid black;
  align-items: center;
}

.switch-container {
  text-align: right;
}

.filter-form {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .switch-container {
    text-align: left;
    margin-top: 10px;
  }

  .title-topo {
    text-align: center;
    margin-bottom: 10px;
  }
}

</style>