<template>
  <span class="stock-container">
    <h2 class="stock-title mt-4 mb-4">Estoque</h2>
    <v-row no-gutters>
      <v-col cols="12" sm="5" md="4" lg="3" xl="2" :align="align">
        <v-btn
          class="mr-4 mb-5 register-btn primary-btn"
          @click="setAllGrid()"
        >
          <v-icon left>mdi-plus-circle</v-icon>
          Cadastrar Grade
        </v-btn>
      </v-col>
    </v-row>
    <span>
      <v-switch
        color="amber darken-4"
        class="switch-inactive"
        @change="setShowInactive(showInactive), setList($route.params.id)"
        v-model="showInactive"
        label="Mostrar Inativos"
      ></v-switch>
      <v-data-table
        :headers="headers"
        :items="list"
        :footer-props="footer"
        class="custom-data-table"
      >
        <template v-slot:item.code_color="{ item }">
          <div
            v-if="item.color !== 'N/A'"
            class="colors-grid-list"
            :style="` ${setBackgroundColorStyle(
              item.code_color,
              item.second_code_color
            )}`"
          >
            {{ item.color }}
          </div>
          <div v-else>
            {{ item.color }}
          </div>
        </template>
        <template v-slot:item.active="{ item }">
          <div :class="`status-${item.active}`">
            {{ checkTrue(item.active) }}
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="action-icon edit-icon"
            @click="edit(item.id)"
            title="Editar"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
      <!-- {{ allGridModal }} -->
    </span>
    <spinner-dialog persistent v-if="modal" :dialog="modal">
      <v-card class="dialog-default">
        <grid-form :id="editId"></grid-form>
      </v-card>
    </spinner-dialog>
    <spinner-dialog persistent v-if="allGridModal" :dialog="allGridModal">
      <v-card class="dialog-default">
        <save-all-grid></save-all-grid>
      </v-card>
    </spinner-dialog>
  </span>
</template>


<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";
import GridForm from "@/views/ProductSession/Pages/Product/Grid/form";
import SaveAllGrid from "@/views/ProductSession/Pages/Product/Grid/Components/saveAllGrid";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";

export default {
  components: {
    GridForm,
    spinnerDialog,
    SaveAllGrid,
  },
  data: function () {
    return {
      showInactive: false,
      editId: "",
      search: "",
      headers: [
        { text: "Qtd", value: "balance", class: "black--text" },
        { text: "Tamanho", value: "size", class: "black--text" },
        { text: "Cor", value: "code_color", class: "black--text" },
        { text: "Ativo", value: "active", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Grid", ["modal", "list", "allGridModal"]),
    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },

  methods: {
    setAllGrid() {
      this.setAllGridModal(!this.allGridModal);
    },
    setBackgroundColorStyle(firstColor, SecondColor) {
      if (!SecondColor) {
        return `background: ${firstColor}`;
      } else {
        return `background: linear-gradient(90deg, ${firstColor} 50%, ${SecondColor} 50%);`;
      }
    },
    checkTrue(value) {
      let response;
      value == true ? (response = "Sim") : (response = "Não");

      return response;
    },
    setNewModal() {
      this.setModal(!this.modal);
      this.editId = null;
    },
    ...mapActions("Grid", [
      "setModal",
      "setList",
      "setAllGridModal",
      "setShowInactive",
    ]),
    edit(item) {
      this.setModal(!this.modal);
      this.editId = item;
    },
  },
  mounted() {
    this.setList(this.$route.params.id);
    toastAlert("success");
  },
  destroyed() {
    this.setShowInactive(false);
  },
};
</script>
<style scoped>
.colors-grid-list {
  border-radius: 10px;
  padding: 10px;
  color: grey;
}



.stock-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.stock-title {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
}

.primary-btn {
  background-color: #1976d2;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #1565c0;
}

.switch-inactive {
  margin-bottom: 20px;
}

.custom-data-table {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.colors-grid-list {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.status-true {
  color: #28a745;
  font-weight: bold;
}

.status-false {
  color: #dc3545;
  font-weight: bold;
}

.action-icon {
  cursor: pointer;
}

.edit-icon {
  color: #1976d2;
}

.edit-icon:hover {
  color: #1565c0;
}

.dialog-default {
  max-width: 1200px;
  border-radius: 8px;
}

</style>