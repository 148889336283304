<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-user">
    <v-container fluid>
      <!-- Título -->
      <v-row>
        <v-col cols="12" class="mb-2">
          <h1 class="title-topo">Lista de Consumidores</h1>
        </v-col>
      </v-row>

       <!-- Campo de Busca -->
       <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            label="Buscar Consumidores"
            outlined
            prepend-inner-icon="mdi-magnify"
            class="custom-search-field"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Painel de Filtros -->
      <v-expansion-panels v-model="panel" class="filter-panel">
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-panel-header">
            <h4>Filtros</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card class="pa-4">
              <v-row dense>
                <v-col v-if="adm" cols="12">
                  <v-autocomplete
                    v-model="form.company_id"
                    :items="companyList"
                    item-text="fantasy_name"
                    item-value="id"
                    label="Loja *"
                    outlined
                    prepend-icon="mdi-store"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <h5 class="mt-4 mb-2">Selecione o período de registro</h5>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.init_date"
                    label="Data Inicial"
                    type="date"
                    outlined
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.finish_date"
                    label="Data Final"
                    type="date"
                    outlined
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="form.state"
                    :items="states"
                    label="Estado"
                    multiple
                    outlined
                    chips
                    prepend-icon="mdi-map-marker"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="form.gender"
                    :items="genders"
                    label="Gênero"
                    multiple
                    outlined
                    chips
                    prepend-icon="mdi-gender-male-female"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row justify="space-between" class="mt-4">
                <v-col cols="auto">
                  <v-btn
                    :disabled="adm && !form.company_id"
                    color="green darken-2"
                    dark
                    @click="setList(form)"
                  >
                    <v-icon left>mdi-filter</v-icon>
                    Filtrar
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn color="red" dark @click="cleanItem()">
                    <v-icon left>mdi-trash-can</v-icon>
                    Limpar Filtros
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Botão Exportar CSV -->
      <v-row class="mt-4">
        <v-col cols="12">
          <v-btn
            :disabled="list.length === 0"
            color="blue darken-2"
            dark
            @click="csvExport(list)"
          >
            <v-icon left>mdi-file-export</v-icon>
            Exportar CSV
          </v-btn>
        </v-col>
      </v-row>

     

      <!-- Tabela de Dados -->
      <v-data-table
        class="default-table"
        :items="list"
        :headers="headers"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            color="blue darken-2"
            class="mr-2"
            @click="openEditPasswordDialog(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>

      <!-- Diálogo de Atualização de Senha -->
      <v-dialog v-model="editPasswordDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Atualizar Senha</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="selectedUser.email"
                label="E-mail"
                type="email"
                readonly
                outlined
              ></v-text-field>
              <v-text-field
                v-model="selectedUser.password"
                label="Nova Senha"
                type="password"
                required
                outlined
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="editPasswordDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="green darken-2" @click="savePassword">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </admin-blade>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import { mapActions, mapState } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";
import { debounce } from "lodash";

export default {
  components: {
    AdminBlade,
  },
  data() {
    return {
      search: "",
      adm: false,
      panel: 0,
      editPasswordDialog: false,
      selectedUser: { user_id: null, email: "", password: "" },
      form: {
        company_id: null,
        init_date: new Date().toISOString().slice(0, 10),
        finish_date: new Date().toISOString().slice(0, 10),
        state: null,
        gender: null,
      },
      genders: [
        { value: "m", name: "Masculino" },
        { value: "f", name: "Feminino" },
        { value: "o", name: "Outros" },
      ],
      states: [
        { value: "AC", name: "AC" },
        // ... outros estados ...
        { value: "TO", name: "TO" },
      ],
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "NOME", value: "fantasy_name", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        { text: "Telefone", value: "phone_number", class: "black--text" },
        { text: "Estado", value: "state", class: "black--text" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
      msgServer: [],
    };
  },
  computed: {
    ...mapState("Users", ["list", "userType"]),
    ...mapState("Company", {
      companyList: "list",
    }),
    filteredList() {
      let filtered = this.list;

      // Aplicar filtros
      if (this.form.company_id) {
        filtered = filtered.filter(
          (item) => item.company_id === this.form.company_id
        );
      }
      if (this.form.init_date && this.form.finish_date) {
        filtered = filtered.filter(
          (item) =>
            new Date(item.created_at) >= new Date(this.form.init_date) &&
            new Date(item.created_at) <= new Date(this.form.finish_date)
        );
      }
      if (this.form.state && this.form.state.length > 0) {
        filtered = filtered.filter((item) =>
          this.form.state.includes(item.state)
        );
      }
      if (this.form.gender && this.form.gender.length > 0) {
        filtered = filtered.filter((item) =>
          this.form.gender.includes(item.gender)
        );
      }

      // Aplicar busca
      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter(
          (item) =>
            item.fantasy_name.toLowerCase().includes(searchTerm) ||
            item.email.toLowerCase().includes(searchTerm)
        );
      }

      return filtered;
    },
  },
  methods: {
    ...mapActions("Users", ["setList", "update"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    edit(item) {
      this.$router.push(`form-user/${item}`);
      this.search = ""; // Limpar a busca ao filtrar
    },
    openEditPasswordDialog(user) {
      this.selectedUser.user_id = user.user_id;
      this.selectedUser.email = user.email;
      this.selectedUser.password = "";
      this.editPasswordDialog = true;
    },
    savePassword() {
      if (!this.selectedUser.password) {
        toastAlert("warning", "Por favor, insira uma nova senha.");
        return;
      }

      const payload = {
        id: this.selectedUser.user_id,
        password: this.selectedUser.password,
        email: this.selectedUser.email,
      };

      this.update(payload)
        .then(() => {
          toastAlert("success", "Senha atualizada com sucesso!");
          this.editPasswordDialog = false;
        })
        .catch(() => {
          toastAlert("error", "Ocorreu um erro ao tentar atualizar a senha.");
        });
    },
    cleanItem() {
      this.form = {
        company_id: null,
        init_date: new Date().toISOString().slice(0, 10),
        finish_date: new Date().toISOString().slice(0, 10),
        state: null,
        gender: null,
      };
      this.search = "";
    },
    getAcessType(value) {
      return value ? "admin-table-class" : "store-table-class";
    },
    onSearch: debounce(function () {
      // A busca já é tratada no computed 'filteredList', então aqui não precisamos fazer nada
    }, 300),
  },
  watch: {
    search: "onSearch",
  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
    toastAlert("success");
  },
};
</script>

<style scoped>
.admin-table-class {
  background: green;
  border-radius: 10px;
  padding: 5px;
  color: white;
}

.store-table-class {
  background: blue;
  border-radius: 10px;
  padding: 5px;
  color: white;
}

.title-topo {
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50;
  border-bottom: 2px solid #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.filter-panel-header {
  font-weight: bold;
  color: #444;
}

.custom-search-field {
  border-radius: 8px;
}

.v-btn {
  border-radius: 8px;
}
</style>
