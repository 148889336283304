<template>
  <span>
    <span v-if="list[0]">
      <!-- Título -->
      <v-row>
        <v-col cols="12">
          <h1 class="title">Endereços</h1>
        </v-col>
      </v-row>

      <!-- Lista de Endereços -->
      <span v-for="item in list" :key="item.id">
        <v-card :class="setClassIfDesableAdress(item.active)" class="mb-4">
          <v-row>
            <v-col cols="12">
              <h2 v-if="!item.active" class="inactive-text">Inativo</h2>
            </v-col>
          </v-row>

          <!-- Informações Básicas -->
          <v-row>
            <v-col cols="7">
              <strong>Rua:</strong> {{ item.street }},
              <strong>N.º</strong> {{ item.number }}
            </v-col>
            <v-col cols="5">
              <strong>Criado:</strong> {{ item.created_at }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <strong>Cidade:</strong> {{ item.city }}-{{ item.state }} / {{ item.cep }}
            </v-col>
            <v-col cols="5">
              <strong>Modificado:</strong> {{ item.updated_at }}
            </v-col>
          </v-row>

          <!-- Observações -->
          <v-row>
            <v-col cols="12">
              <span v-if="item.note">
                <strong>Observações:</strong>
                <p>{{ item.note }}</p>
              </span>
            </v-col>
          </v-row>

          <!-- Botão de Alterar -->
          <v-row justify="end">
            <v-col cols="12" class="text-right">
              <v-btn class="update-btn" @click="edit(item.id)">Alterar</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </span>
    </span>

    <!-- Nenhum Endereço -->
    <span v-if="!list[0]">
      <hr class="mb-5" />
      <h1 class="no-address">Nenhum Endereço Cadastrado</h1>
    </span>

    <!-- Botão Novo Endereço -->
    <v-row justify="end">
      <v-col cols="12" class="text-right">
        <v-btn class="save-btn" @click="setAdressDialog(true)">Novo Endereço</v-btn>
      </v-col>
    </v-row>

    <hr class="mt-5" />

    <!-- Dialog de Cadastro -->
    <v-row justify="center">
      <spinner-dialog :dialog="adressDialog">
        <v-card class="dialog-default">
          <form-adress :companyId="companyId"></form-adress>
        </v-card>
      </spinner-dialog>
    </v-row>
  </span>
</template>


<script>
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import formAdress from "@/views/Administration/Pages/Company/components/Adress/formAdress";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    formAdress,
    spinnerDialog,
  },

  data: function () {
    return {};
  },
  props: {
    companyId: Number,
  },
  computed: {
    ...mapState("Adress", ["list"]),
    ...mapState("Adress", ["adressDialog"]),
  },
  methods: {
    ...mapActions("Adress", ["setList"]),
    ...mapActions("Adress", ["setAdressDialog"]),
    ...mapActions("Adress", ["show"]),

    edit(id) {
      this.show({ id: id });
      this.setAdressDialog(true);
    },
    setClassIfDesableAdress(value) {
      if (value == true) {
        return "mb-4 list-card";
      } else {
        return "mb-4 list-card-off";
      }
    },
  },
  mounted() {
    this.setList(this.companyId);
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000000;
  text-align: center;
}

.inactive-text {
  color: #e53935;
  font-size: 18px;
  margin-left: 20px;
}

.list-card {
  background-color: #ffffff;
  color: #333333;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.list-card-off {
  background-color: rgba(192, 187, 187, 0.397);
  color: #777777;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.update-btn {
  color: white;
  background-color: #4caf50;
  text-transform: uppercase;
  font-weight: bold;
}

.update-btn:hover {
  background-color: #388e3c;
}

.no-address {
  font-size: 18px;
  font-weight: bold;
  color: #e53935;
  text-align: center;
}

.save-btn {
  background-color: #2196f3;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.save-btn:hover {
  background-color: #1976d2;
}

.dialog-default {
  padding: 20px;
  border-radius: 8px;
}

</style>