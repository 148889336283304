<template>
  <span>
    <span v-if="list[0]">
      <!-- Título -->
      <v-row>
        <v-col cols="12">
          <h1 class="title">Telefones</h1>
        </v-col>
      </v-row>

      <!-- Tabela de Telefones -->
      <v-card class="table-container elevation-2">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Tipo</th>
                <th class="text-left">Número</th>
                <th class="text-left">Editar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in list"
                :key="item.id"
                :class="checkCanceled(item.active)"
              >
                <td>{{ transformType(item.type) }}</td>
                <td>{{ numberMask(item.number) }}</td>
                <td>
                  <v-btn text icon @click="edit(item.id)">
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </span>

    <!-- Nenhum Telefone Cadastrado -->
    <span v-else>
      <v-row justify="center">
        <v-col cols="12">
          <h1 class="no-phone">Nenhum Telefone Cadastrado</h1>
        </v-col>
      </v-row>
    </span>

    <!-- Dialog de Cadastro -->
    <v-row justify="center">
      <spinner-dialog :dialog="phoneDialog">
        <v-card class="dialog-default">
          <form-phone :company_id="companyId"></form-phone>
        </v-card>
      </spinner-dialog>
    </v-row>

    <!-- Botão de Cadastrar -->
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn class="save-btn" @click="setPhoneDialog(true)">
          Cadastrar Telefone
        </v-btn>
      </v-col>
    </v-row>
  </span>
</template>


<script>
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import formPhone from "@/views/Administration/Pages/Company/components/Phones/formPhone";
import { mapActions, mapState } from "vuex";
import { transformTypeInName } from "@/utils/phoneFunctions";
import { modifyNumberForMaskPhone } from "@/utils/masksFunctions";

export default {
  props: {
    companyId: Number,
  },
  components: {
    formPhone,
    spinnerDialog,
  },
  computed: {
    ...mapState("Phone", ["phoneDialog"]),
    ...mapState("Phone", ["list"]),
  },
  methods: {
    ...mapActions("Phone", ["setPhoneDialog", "setList", "show"]),
    edit(id) {
      this.show({ id: id });
      this.setPhoneDialog(true);
    },
    transformType(type) {
      return transformTypeInName(type);
    },
    numberMask(number) {
      return modifyNumberForMaskPhone(number);
    },
    checkCanceled(value) {
      return !value ? "canceled-item" : "";
    },
  },
  mounted() {
    this.setList(this.companyId);
  },
};
</script>

<style>
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000000;
  text-align: center;
}

.table-container {
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.v-simple-table th {
  font-weight: bold;
  text-transform: uppercase;
  color: #333333;
  background-color: #f5f5f5;
}

.v-simple-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.canceled-item {
  text-decoration: line-through;
  color: #e53935;
}

.no-phone {
  font-size: 18px;
  font-weight: bold;
  color: #e53935;
  text-align: center;
}

.save-btn {
  background-color: #2196f3;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  margin-top: 20px;
}

.save-btn:hover {
  background-color: #1976d2;
}

.dialog-default {
  padding: 20px;
  border-radius: 8px;
}

</style>