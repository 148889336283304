<template>
  <v-form flat class="v-form mt-5">
    <div class="default-form">
      <!-- Header -->
      <v-row>
        <v-col cols="12">
          <h3 class="form-title">Filtros</h3>
          <v-divider class="mb-1"></v-divider>
        </v-col>
      </v-row>

      <!-- Input Fields -->
      <v-row>
        <v-col cols="12" sm="6" md="12">
          <v-text-field
            clearable
            dense
            v-model="productName"
            @keydown.enter="resetPage(), setList()"
            label="Nome do produto"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="definition"
            @keydown.enter="resetPage(), setList()"
            label="Segmento"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="category"
            @keydown.enter="resetPage(), setList()"
            label="Categoria"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="subcategory"
            @keydown.enter="resetPage(), setList()"
            label="Subcategoria"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            clearable
            dense
            v-model="reference"
            @keydown.enter="resetPage(), setList()"
            label="Referência"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="adm">
          <v-autocomplete
            v-model="client"
            :items="companyList"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            @change="resetPage(), setList()"
            color="amber darken-4"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>

      <!-- Grid Filters -->
      <v-switch label="Pesquisar por grade" v-model="showGrid" class="mt-4"></v-switch>
      <v-row v-if="showGrid">
        <v-col cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="gridCode"
            @keydown.enter="resetPage(), setList()"
            label="Código da grade"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="gridColor"
            @keydown.enter="resetPage(), setList()"
            label="Cor"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="gridSize"
            @keydown.enter="resetPage(), setList()"
            label="Tamanho"
            outlined
          />
        </v-col>
      </v-row>

      <!-- Action Buttons -->
      <v-row justify="center" class="mt-4">
        <v-col cols="12" sm="8" md="6" lg="6" class="text-center">
          <v-btn color="green"  
          dark
          rounded
          small @click="resetPage(), setList()" class="action-btn">
            Filtrar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="6" lg="6" class="text-center">
          <v-btn
            color="red"
            dark
            rounded
            small
            @click="cleanFilters(), resetPage(), setList()"
            class="action-btn"
          >
            Limpar filtros <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>


<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      adm: false,
    };
  },
  props: {
    list: null,
  },
  computed: {
    ...mapState("Company", {
      companyList: "list",
    }),
    productName: {
      get() {
        return this.$store.state.Product.filter.productName;
      },
      set(value) {
        this.$store.commit("Product/SET_PRODUCTNAME", value);
      },
    },
    reference: {
      get() {
        return this.$store.state.Product.filter.reference;
      },
      set(value) {
        this.$store.commit("Product/SET_REFERENCE", value);
      },
    },
    definition: {
      get() {
        return this.$store.state.Product.filter.definition;
      },
      set(value) {
        this.$store.commit("Product/SET_DEFINITION", value);
      },
    },
    category: {
      get() {
        return this.$store.state.Product.filter.category;
      },
      set(value) {
        this.$store.commit("Product/SET_CATEGORY", value);
      },
    },
    subcategory: {
      get() {
        return this.$store.state.Product.filter.subcategory;
      },
      set(value) {
        this.$store.commit("Product/SET_SUBCATEGORY", value);
      },
    },

    client: {
      get() {
        return this.$store.state.Product.filter.client;
      },
      set(value) {
        this.$store.commit("Product/SET_CLIENTFILTER", value);
      },
    },

    // ---

    showGrid: {
      get() {
        return this.$store.state.Product.filter.grid.showGrid;
      },
      set(value) {
        this.$store.commit("Product/SET_SHOWGRID", value);
      },
    },
    gridCode: {
      get() {
        return this.$store.state.Product.filter.grid.code;
      },
      set(value) {
        this.$store.commit("Product/SET_GRIDCODE", value);
      },
    },
    gridColor: {
      get() {
        return this.$store.state.Product.filter.grid.color;
      },
      set(value) {
        this.$store.commit("Product/SET_COLOR", value);
      },
    },
    gridSize: {
      get() {
        return this.$store.state.Product.filter.grid.size;
      },
      set(value) {
        this.$store.commit("Product/SET_SIZE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
  },
  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("Product", ["setList"]),
    cleanFilters() {
      this.productName = "";
      this.reference = "";
      this.definition = "";
      this.category = "";
      this.subcategory = "";
      this.code = "";
      this.color = "";
      this.size = "";
      this.client = "";
    },
    onload() {
      // toastAlert("success");

      this.adm = JSON.parse(localStorage.getItem("user")).admin;

      if (this.adm) {
        this.setCompany();
      }
    },
    resetPage() {
      this.page = 1;
    },
  },
  mounted() {
    this.onload();
  },
};
</script>

<style>
.default-form {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
}

.v-text-field {
  margin-bottom: 16px;
}

.v-switch {
  margin-top: 10px;
}

.action-btn {
  width: 100%;
  font-weight: bold;
}

</style>