<template>
  <v-form flat class="v-form mt-5">
    <div class="default-form">
      <!-- Título -->
      <v-row>
        <v-col cols="12">
          <h2 class="form-title">Filtros</h2>
          <v-divider class="mb-4"></v-divider>
        </v-col>
      </v-row>

      <!-- Campos do Formulário -->
      <v-row>
        <!-- Loja -->
        <v-col cols="12" sm="6" md="4" v-if="admin">
          <v-autocomplete
            v-model="filter.company_id"
            :items="companyList"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            outlined
            dense
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <!-- Definição -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="filter.definition"
            @keyup.enter="setList(filter)"
            label="Segmento"
            outlined
          />
        </v-col>

        <!-- Categoria -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="filter.category"
            @keyup.enter="setList(filter)"
            label="Categoria"
            outlined
          />
        </v-col>

        <!-- Subcategoria -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="filter.subcategory"
            @keyup.enter="setList(filter)"
            label="Subcategoria"
            outlined
          />
        </v-col>

        <!-- Quantidade de Cards -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="filter.qtd_cards"
            :items="items"
            label="Qtd de Cards por Linha"
            outlined
            dense
          ></v-select>
        </v-col>

        <!-- Opções de Promoção -->
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            v-model="filter.promotionRule"
            :items="promotionRule"
            item-text="name"
            item-value="value"
            label="Opções de Promoção"
            outlined
            dense
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <!-- Gênero -->
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            v-model="filter.gender"
            :items="gender"
            item-text="name"
            item-value="value"
            label="Gênero"
            outlined
            dense
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <!-- Switch -->
      <v-row>
        <v-col cols="12" sm="4">
          <v-switch
            v-model="filter.show_img"
            label="Trazer Imagens?"
            inset
          ></v-switch>
        </v-col>
      </v-row>

      <!-- Botões de Ação -->
      <v-row justify="center" class="mt-4">
        <v-col cols="12" sm="6" md="4" class="text-center">
          <v-btn
            dark
            rounded
            color="green"
            @click="setList()"
            class="action-btn"
          >
            Filtrar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="text-center">
          <v-btn
            dark
            rounded
            color="red"
            @click="cleanItem()"
            class="action-btn"
          >
            Limpar Filtros <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>


<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      items: [2, 3, 4, 6],
    };
  },
  props: {
    list: null,
    admin: null,
  },
  computed: {
    ...mapState("LayoutOrganization", ["filter", "promotionRule", "gender"]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("LayoutOrganization", ["setList", "cleanItem"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    cleanFilters() {
      this.definition = "";
      this.category = "";
      this.subcategory = "";
      this.client = "";
    },
    resetPage() {
      this.page = 1;
    },
  },

  mounted() {
    this.setList();
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id });
    }
    if (this.adm) {
      this.setCompany();
    }
  },
  destroyed() {
    this.cleanItem();
  },
};
</script>

<style>
.default-form {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}

.v-text-field,
.v-select,
.v-autocomplete {
  margin-bottom: 16px;
}

.v-switch {
  margin-top: 10px;
}

.action-btn {
  width: 100%;
  font-weight: bold;
}

</style>