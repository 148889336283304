<template>
  <v-card class="pagarme-pgto-card">
    <h3>Integração Pagar.me</h3>

    <div v-if="errors">
      <br>
      <h3>Erro: A validade do token de 3 minutos</h3>
      <v-btn class="btnFinalizar" @click="setDataIntegrationPagarme()">Tentar Novamente</v-btn>
    </div>

 
    <div v-if="$route.query.authorization_code && !errorMessage && !errors && !active">

      <v-btn class="btnFinalizar" @click="savePagarmev5({code: $route.query.authorization_code, company_id: formData.company_id})">Finalizar Minha Integração</v-btn>
    </div>

    <v-btn v-if="!errorMessage && !$route.query.authorization_code && !errors && !active" id="pagarme-hub" @click="setDataIntegrationPagarme()">
      Integrar com Pagarme
    </v-btn>

    <div class="Integrado" v-if="active">
      <p class="txtIntegrado">Você já está integrado !!!</p>
      <v-btn id="pagarme-hub" class="btnAtualizar" @click="setDataIntegrationPagarme()">
        Atualizar integração Pagar.me
      </v-btn>
    </div>

  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { publicAppKey, myUrl, environment } from "@/global";

export default {
  data() {
    return {
      tokenValue: false,
      config: Object,
      installId: null,
      errorMessage: null,
      key_name: "pagarmev5", // Chave a ser atualizada
    };
  },
  methods: {
    ...mapActions("PagarmeIntermediary", [
      "savePv",
      "savePagarmev5",
      "cleanState",
      "saveToken",
      "check",
      "updateValue",
      "sendDataToHub",
    ]),
    ...mapActions("ReceiveData", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),

  
     updateKeyName() {
      const payload = {
        id: this.formData.id, 
        company_id: this.formData.company_id,
        key_name: this.key_name, 
      };

      // Incluindo campos opcionais apenas se estiverem preenchidos
      if (this.formData.extra_receive_info) {
        payload.extra_receive_info = JSON.stringify(this.formData.extra_receive_info);
      }
      if (this.formData.installments) {
        payload.installments = this.formData.installments;
      }
      if (this.formData.soft_descriptor) {
        payload.soft_descriptor = this.formData.soft_descriptor;
      }
      if (this.formData.email) {
        payload.email = this.formData.email;
      }

      // console.log("Enviando payload:", payload);

      this.update(payload)
        .then(() => {
          // console.log("Atualização de key_name realizada com sucesso.");
        })
        .catch((error) => {
          console.error("Erro ao atualizar key_name:", error.response.data.errors);
        });
},


    setDataIntegrationPagarme() {
      this.config = {
        publicAppKey: publicAppKey,
        installId: this.getInstallId(),
        redirectUrl: `${myUrl}/form-receive-data/${this.$route.params.id}`,
        language: "pt-BR",
        environment: environment,
      };

      // console.log("Iniciando integração com Pagarme...");
      this.updateKeyName();

      this.sendDataToHub(this.config);
    },


    getInstallId() {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("installId")) {
        this.installId = searchParams.get("installId");
      }
      return this.installId;
    },
  },
  computed: {
    ...mapState("ReceiveData", ["formData"]),
    ...mapState("PagarmeIntermediary", [
      "spinner",
      "errors",
      "pvId",
      "tokenId",
      "active"
    ]),
    dialog: {
      get() {
        return this.$store.state.RedeIntrermediary.dialog;
      },
      set(value) {
        this.$store.commit("RedeIntrermediary/SET_DIALOG", value);
      },
    },
  },
  mounted() {
    this.check({ company_id: this.formData.company_id });
  },
};
</script>



<style scoped>
@media (max-width: 999px) {
  .Integrado{
    width: 100% !important;
  }
  .btnAtualizar{
    width: 280px !important;
    font-size: 10px !important;
    font-weight: 1000;
  }
}
.Integrado{
  background: #efe8e8;
  width: 50%;
  border-radius: 20px !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.btnAtualizar{
  background: red !important;
  color: white;
}
.txtIntegrado{
  color: black;
  font-size: 1rem;
  
  font-weight: 700;
}
.btnFinalizar{
  background-color: #e66b25 !important; 
  width: 500px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  height: 100px !important;
  margin-top: 30px;
}
.pagarme-pgto-card {
  border: solid grey 1px;
  box-shadow: none !important;
  padding: 20px;
}
</style>
