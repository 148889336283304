<template>
  <span>
    <admin-blade iconBtnTop="mdi-store" routeBtnTop="/company">
      <v-card class="default-form-bg elevation-2">
        <v-form class="mt-5 default-form" ref="form" v-model="valid">
          <!-- Título -->
          <v-row>
            <v-col cols="12">
              <h1 class="title-topo">Dados Cliente</h1>
              <p class="subtitle">Preencha os dados do cliente ou da empresa.</p>
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <!-- Switch Empresa -->
          <v-row >
            <v-col sm="4" md="4" cols="12">
              <v-switch
                @change="companyRules(formData.company)"
                color="amber darken-4"
                v-model="formData.company"
                label="Empresa"
                inset
              ></v-switch>
              </v-col>
              <v-col sm="4" md="4" cols="12">
                <p v-if="formData.updated_at">
                  <strong>Última Modificação:</strong>
                  {{ toConvertData(formData.updated_at) }}
                </p>
              </v-col>
              <v-col sm="4" md="4" cols="12">
                <p v-if="formData.created_at">
                  <strong>Data Cadastro:</strong>
                  {{ toConvertData(formData.created_at) }}
                </p>
              </v-col>
          </v-row>

          <!-- Campos Básicos -->
          <v-row>
            <v-col sm="6" md="6" cols="12">
              <v-text-field
                v-model="formData.fantasy_name"
                :rules="rulesFunction('Nome', 2)"
                label="Nome *"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.fantasy_name }}</strong>
            </v-col>
            <v-col sm="6" cols="12" v-if="formData.company">
              <v-text-field
                v-model="formData.social_name"
                :rules="rulesFunction('Razão Social', 2)"
                label="Razão Social *"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.social_name }}</strong>
            </v-col>
          </v-row>

          <!-- Campos Complementares -->
          <v-row>
            <v-col sm="6" md="6" cols="12">
              <v-text-field
                v-model="formData.birhtday"
                label="Data de Nascimento *"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="6" cols="12">
              <v-text-field
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                v-model="formData.cpf_cnpj"
                :rules="rulesFunction(cpfCnpjLabel, CpfSize)"
                :label="cpfCnpjLabel"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.cpf_cnpj }}</strong>
            </v-col>
            <v-col sm="6" md="6" cols="12">
              <v-text-field
                v-mask="['##.###.###-#', '########-##']"
                v-model="formData.ie_rg"
                :label="ieRg"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.ie_rg }}</strong>
            </v-col>
            <v-col sm="6" md="6" cols="12">
              <v-text-field
                v-model="formData.email"
                :rules="emailRules"
                label="E-mail"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.email }}</strong>
            </v-col>
          </v-row>

          <!-- Observações e Links -->
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="formData.note"
                label="Observações"
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.page_url"
                label="Endereço Página"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.page_url }}</strong>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.logo_url"
                label="Endereço Logo"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.logo_url }}</strong>
            </v-col>
          </v-row>

          <!-- Datas -->
          <v-row>
            <v-col sm="6" cols="12">
              <v-text-field
                type="date"
                v-model="formData.contract_close"
                label="Data Fechamento Contrato"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.contract_close }}</strong>
            </v-col>
            <v-col sm="6" cols="12">
              <v-text-field
                type="date"
                v-model="formData.project_delivery"
                label="Data Entrega Projeto"
                outlined
                dense
              ></v-text-field>
              <strong class="error-text">{{ errors.project_delivery }}</strong>
            </v-col>
          </v-row>

          <!-- Switches Adicionais -->
          <v-row>
            <v-col sm="4" cols="12">
              <v-switch
                color="amber darken-4"
                v-model="formData.delivery_system"
                label="Sistema de Entregas"
                inset
              ></v-switch>
            </v-col>
            <v-col sm="2" cols="12" v-if="formData.id">
              <v-switch
                color="amber darken-4"
                v-model="formData.active"
                label="Ativo"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <!-- Informações Adicionais -->
          <v-row>
        
          </v-row>

          <!-- Botões -->
          <v-row justify="center" class="mt-5">
            <v-btn
              v-if="id"
              color="green darken-2"
              @click="SaveUpdateWithMask('update')"
              large
            >
              Salvar Alterações
            </v-btn>
            <v-btn
              v-else
              :disabled="!valid"
              color="green darken-2"
              @click="SaveUpdateWithMask('save')"
              large
            >
              Salvar Loja
            </v-btn>
          </v-row>

          <!-- Listas de Telefones e Endereços -->
          <hr class="mt-5" v-if="formData.id" />
          <list-phone v-if="formData.id" :companyId="formData.id"></list-phone>
          <hr class="mt-5" v-if="formData.id" />
          <list-adress v-if="formData.id" :companyId="formData.id"></list-adress>
        </v-form>
      </v-card>
    </admin-blade>
  </span>
</template>


<script>
import { mapActions, mapState } from "vuex";
import listAdress from "@/views/Administration/Pages/Company/components/Adress/listAdress";
import listPhone from "@/views/Administration/Pages/Company/components/Phones/listPhone";
import AdminBlade from "@/Layouts/Blade";
import { dateFormatDMA } from "@/utils/dateFunctions";
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mask } from "vue-the-mask";
import { removeOverCaractersForNumbers } from "@/utils/masksFunctions";
// import { saveImage } from "@/utils/Images/base64Converters";
import { toUpperCaseFirstLetter } from "@/utils/textFunctions";

export default {
  directives: { mask },

  components: {
    AdminBlade,
    listAdress,
    listPhone,
  },
  data: function () {
    return {
      newAdress: false,
      ieRg: "I. Estadual",
      cpfCnpjLabel: "CNPJ",
      id: this.$route.params.id,
      valid: false,
      CpfSize: 18,
      emailRules: [
        (v) => !!v || "Por favor insira um e-mail",
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser valido",
      ],
    };
  },
  computed: {
    ...mapState("Company", ["errors"]),
    ...mapState("Company", ["formData"]),
  },
  methods: {
    ...mapActions("Company", ["save"]),
    ...mapActions("Company", ["update"]),
    ...mapActions("Company", ["show"]),
    ...mapActions("Company", ["cleanErrors"]),
    ...mapActions("Company", ["cleanItem"]),
    ...mapActions("Adress", ["cleanAdressList"]),
    ...mapActions("Phone", ["cleanPhoneList"]),

    rulesFunction(name, lengthNeed) {
      rulesValidationFunction(name, lengthNeed);
    },

    SaveUpdateWithMask(saveOrUpdate) {
      this.formData.fantasy_name = toUpperCaseFirstLetter(
        this.formData.fantasy_name
      );
      this.formData.fantasy_name = toUpperCaseFirstLetter(
        this.formData.fantasy_name
      );

      if (this.formData.ie_rg) {
        this.formData.ie_rg = removeOverCaractersForNumbers(
          this.formData.ie_rg
        );
      }
      if (this.formData.cpf_cnpj) {
        this.formData.cpf_cnpj = removeOverCaractersForNumbers(
          this.formData.cpf_cnpj
        );
      }
      if (saveOrUpdate == "save") {
        this.save(this.formData);
      } else {
        this.update(this.formData);
      }
    },
    companyRules(value) {
      if (value) {
        this.ieRg = "I. Estadual";
        this.cpfCnpjLabel = "CNPJ";
        this.CpfSize = 18;
      } else {
        this.formData.social_name = "";
        this.cpfCnpjLabel = "CPF";
        this.ieRg = "RG";
        this.CpfSize = 14;
      }
    },
    toConvertData(data) {
      return dateFormatDMA(data);
    },
  },
  mounted() {
    if (this.id) {
      this.show(this.id);
      this.CpfSize = 11;
    }
  },
  destroyed() {
    this.cleanErrors();
    this.cleanItem();
    this.cleanAdressList();
    this.cleanPhoneList();
  },
};
</script>

<style scoped>
.default-form-bg {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.title-topo {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  border-bottom: 0.5px solid black;
}

.subtitle {
  text-align: center;
  font-size: 16px;
  color: #414141;
  margin-bottom: 10px;
}

.error-text {
  color: #e53935;
  font-size: 12px;
}

.v-btn {
  width: 200px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  margin-bottom: 20px;
}

.v-btn:hover {
  background-color: #1b5e20;
}

@media (max-width: 960px) {
  .v-btn {
    width: 100%;
  }
}

</style>