<template>
  <admin-blade iconBtnTop="mdi-bookmark" routeBtnTop="/definition">
    <span class="default-form-bg">
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
        <h1 class="title-topo">Formulario p/ Segmento</h1>
        <v-row>
          <v-col sm="12" cols="12">
            <v-text-field v-model="formData.name" :rules="rulesFunction('Nome', 2)" label="Nome *"></v-text-field>
            <strong class="red--text">{{errors.name}}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
        >Salvar Alterações</v-btn>
        <v-btn
          v-else
          :disabled="!valid"
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
        >Salvar</v-btn>
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade
  },
  data: function() {
    return {
      valid: false
    };
  },
  computed: {
    ...mapState("Definition", ["userType", "errors", "formData"])
  },
  methods: {
    ...mapActions("Definition", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update"
    ]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  }
};
</script>

<style>
</style>