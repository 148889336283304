<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-receive-data">
    <!-- Cabeçalho -->
    <v-row class="header-row">
      <v-col cols="12">
        <h1 class="title-topo">Gerenciamento de Dados de Recebimentos</h1>
      </v-col>
    </v-row>

    <!-- Filtros -->
    <v-card class="filters-card">
      <v-row>
        <!-- Campo de Busca -->
        <v-col cols="12" md="12">
          <v-text-field
            class="default-search"
            v-model="search"
            label="Buscar por cliente ou intermediador"
            clearable
          >
            <template v-slot:prepend-inner>
              <v-icon color="grey darken-2">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Tabela -->
    <v-card class="table-card">
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
        dense
      >
        <!-- Ações -->
        <template v-slot:item.action="{ item }">
          <v-btn icon small @click="edit(item.id)" title="Editar">
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Cliente", value: "intermediary", class: "black--text" },
        { text: "Intermediador", value: "fantasy_name", class: "black--text" },
        {
          text: "Captura Baixo Risco",
          value: "capture_low_risk",
          class: "black--text",
        },
        {
          text: "Evitar Alto Risco",
          value: "void_high_risk",
          class: "black--text",
        },
        {
          text: "Ações",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("ReceiveData", ["list"]),
  },
  methods: {
    ...mapActions("ReceiveData", ["setList"]),
    edit(item) {
      this.$router.push(`/form-receive-data/${item}`);
    },
  },
  mounted() {
    if (!this.user.admin) {
      this.$router.push(`/form-receive-data`);
    } else {
      this.setList();
      toastAlert("success");
    }
  },
  watch: {
    dataSaved(newVal) {
      if (newVal) {
        // Limpa o cache do componente
        this.search = "";
        this.$store.dispatch("ReceiveData/clearList"); // Certifique-se de ter uma ação `clearList` no Vuex, se necessário.

        // Atualiza a página ou redireciona
        this.$router.go(0); // Atualiza a página inteira
      }
    },
  },
  beforeDestroy() {
    // Este hook é chamado antes que o componente seja destruído
    this.cleanData();
  },
};
</script>

<style scoped>
/* Cabeçalho */
.header-row {
  margin-bottom: 20px;
  animation: fadeIn 0.5s ease-out;
}

.title-topo {
  font-size: 28px;
  font-weight: bold;
  color: #3f51b5; /* Azul para destaque */
  border-bottom: 3px solid #7986cb; /* Azul mais claro */
  padding-bottom: 8px;
  margin-bottom: 16px;
}

/* Card de Filtros */
.filters-card {
  margin-bottom: 20px;
  background-color: #e3f2fd; /* Azul muito claro */
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-out;
  align-content: start;
}

.default-search {
  border-radius: 8px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

.default-search:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.default-search >>> .v-icon {
  font-size: 22px;
}

/* Card da Tabela */
.table-card {
  border-radius: 12px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.6s ease-out;
}

/* Tabela */
.v-data-table th {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #e8eaf6; /* Azul muito claro */
}

.v-data-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

/* Botões de Ação */
.v-btn {
  margin: 0;
  padding: 0;
}

.v-btn:hover {
  background-color: rgba(63, 81, 181, 0.1); /* Fundo azul claro ao passar o mouse */
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
