<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-product-group">
    <!-- Cabeçalho -->
    <v-row class="header-row">
      <v-col cols="12">
        <h1 class="title-topo">Gerenciamento de Grupos de Produtos</h1>
      </v-col>
    </v-row>

    <!-- Campo de Busca -->
    <v-card class="filters-card">
      <v-row>
        <!-- Campo de Busca -->
        <v-col cols="12" md="6">
          <v-text-field
            class="default-search"
            v-model="search"
            label="Buscar por nome ou atributo"
            outlined
            clearable
          >
            <template v-slot:prepend-inner>
              <v-icon color="grey darken-2">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <!-- Botão Novo Grupo -->
        <v-col cols="12" md="6" class="d-flex align-center justify-end">
          <v-btn color="primary" @click="addProductGroup" class="add-group-btn">
            <v-icon left>mdi-plus</v-icon> Novo Grupo
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- Exibição Condicional: Mobile ou Desktop -->
    <v-card class="table-card">
      <list-iterator class="d-block d-md-none" :list="list" />
      <list-table class="d-none d-md-block" :list="list" :search="search" />
    </v-card>
  </admin-blade>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import ListIterator from "@/views/ProductSession/Pages/ProductGroup/iterator.vue";
import ListTable from "@/views/ProductSession/Pages/ProductGroup/table.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      search: null,
    };
  },
  components: {
    AdminBlade,
    ListIterator,
    ListTable,
  },
  methods: {
    addProductGroup() {
      this.$router.push("/form-product-group/");
    },
  },
  computed: {
    ...mapState("ProductGroup", ["list"]),
  },
};
</script>

<style scoped>
/* Cabeçalho */
.header-row {
  margin-bottom: 20px;
}

.title-topo {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

/* Card de Filtros */
.filters-card {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
}

.default-search {
  border-radius: 8px;
  background-color: #ffffff;
}

.default-search >>> .v-icon {
  font-size: 22px;
}

/* Botão Novo Grupo */
.add-group-btn {
  text-transform: uppercase;
  font-weight: bold;
}

/* Card da Tabela */
.table-card {
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
