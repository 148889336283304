

import PendingBuys from '@/views/OrderSession/pages/PendingBuys/list'
import DetailOrder from '@/views/OrderSession/pages/DetailOrder/form'
import SortingOutBuys from '@/views/OrderSession/pages/SortingOutBuys/list'
import InTransitBuys from '@/views/OrderSession/pages/InTransitBuys/list'
import orderListDefault from '@/views/OrderSession/pages/orderListDefault/list'
import CancelOrder from '@/views/OrderSession/pages/CancelOrder/list'


const routes = [
    {
        path: '/finished',
        props: true,
        component: orderListDefault,
    },
    {
        path: '/not-finished-buys',
        props: true,
        component: orderListDefault,
    },
    {
        path: '/pendingBuys',
        props: true,
        component: PendingBuys,
    },
    {
        path: '/detailOrder',
        props: true,
        component: DetailOrder,
        children: [
            { path: ':id', component: DetailOrder, props: true, }
        ]
    },
    {
        path: '/sortingOutBuys',
        props: true,
        component: SortingOutBuys
        //children: [
        //    { path: ':id', component: FormSubCategory, props: true, }
        //]
    },
    {
        path: '/get-buys-canceled',
        props: true,
        component: CancelOrder
        //children: [
        //    { path: ':id', component: FormSubCategory, props: true, }
        //]
    },
    {
        path: '/inTransitBuys',
        props: true,
        component: InTransitBuys
        //children: [
        //    { path: ':id', component: FormSubCategory, props: true, }
        //]
    },

]


export default routes
