<template>
  <div class="main-order-report">
    <report-modal
      reportTitle="Relatorio de Pedidos"
      informationName="Periodo:"
      optionalInformationName="Status: "
    >
      <v-btn @click="csvExport(getOrganizedList)">Exportar CSV</v-btn>

      <!-- {{ getOrganizedList }} -->
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="getOrganizedList"
        :search="search"
        :footer-props="footer"
      >
        <!-- <template v-slot:item.delivery_price="{ item }">
          <span
            >R$
            {{
              parseFloat(item.delivery_price).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}</span
          >
        </template>
        <template v-slot:item.products_value="{ item }">
          <span
            >R$
            {{
              parseFloat(item.products_value).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}</span
          >
        </template>
        <template v-slot:item.status="{ item }">
          <span>{{ changeStatusToName(item.status) }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
        <template v-slot:item.total="{ item }">
          R$
          {{ sumTotalValue(item.products_value, item.delivery_price) }}
        </template> -->
      </v-data-table>
      <!-- <v-card>
        <v-card-title> -->
    </report-modal>
  </div>
</template>

<script>
import ReportModal from "@/views/Reports/Components/ReportModal";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    ReportModal,
  },

  data() {
    return {
      search: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Nome", value: "title" },
        { text: "Segmentos", value: "product_type" },
        { text: "Link", value: "link" },
        { text: "Img ink", value: "image_link" },
        { text: "Descrição", value: "description" },
        { text: "Preço", value: "price" },
        { text: "Preço Promocional", value: "sale_price" },
        { text: "Fim da Promoção", value: "sale_price_effective_date" },
        { text: "Marca", value: "brand" },
        { text: "Genero", value: "gender" },
        // { text: "Sub Categoria", value: "subcategory" },
        // { text: "marca", value: "brand" },

        // { text: "Cupom", value: "coupon_discount" },

        // { text: "Intermediador Pgto", value: "payment_source" },
        // { text: "Meio Pgto", value: "type" },
        // { text: "Parcelas", value: "installments" },
        // { text: "Tentativas Pgto", value: "payment_try" },
        // { text: "Vl Entr.(R$)", value: "delivery_price" },
        // { text: "Vl Prod.(R$)", value: "products_value" },
      ],
    };
  },
  methods: {
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  computed: {
    ...mapState("Spinner", ["modalReportSpinner"]),
    // ...mapState("FacebookCatalogReport", ["list"]),
    ...mapState("Utils", ["paymentStatus"]),
    ...mapGetters("FacebookCatalogReport", ["getOrganizedList"]),
    // csvData() {
    //   return this.users.map((item) => ({
    //     ...item,
    //     address: "адрес", //item.address.city,
    //     company: "компания", //item.company.name
    //   }));
    // },
  },
};
</script>

<style scoped>
.order-report-row-top {
  border: solid rgba(128, 128, 128, 0.137) 1px !important;
  background: #f4f7fc !important;
  margin: 0 10px 0 10px;
  padding: 10px 0 10px 0;
}
.main-order-report {
  background: #f4f7fc !important;
}

.sale-information-class {
  border-left: solid grey 1px;
  padding: auto 0 auto 0 !important;
}

@media (max-width: 959px) {
  .sale-information-class {
    border-left: none;
    border-top: solid grey 1px;
    padding: auto 0 auto 0 !important;
  }
}
</style>