<template>
  <div
    style="background-color: white !important; height: 400px"
    class="main-relatorio"
  >
    <div>
      <h1 class="text-center">Relatório de Pedidos</h1>
    </div>
    <!-- {{ form.init_only_date }} -->
    <div class="filter-class">
      <v-row>
        <v-col
          cols="12"
          class="filter-col-class mb-3"
          style="border-bottom: solid grey 1px"
          ><h4>Filtros:</h4></v-col
        >
        <v-col v-if="adm" cols="12">
          <v-autocomplete
            v-model="form.company_id"
            :items="companyList"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="filter-col-class" cols="12" md="3">
          <v-text-field
            style="width: 100% !important"
            type="date"
            v-model="form.init_only_date"
            label="Data Inicial"
          ></v-text-field>
        </v-col>
        <v-col class="filter-col-class" cols="12" md="2">
          <v-text-field
            type="time"
            v-model="form.init_time"
            label="Hora Inicial"
          ></v-text-field>
        </v-col>
        <v-col class="filter-col-class" cols="12" md="3">
          <v-text-field
            type="date"
            v-model="form.finish_only_date"
            label="Data Final"
          ></v-text-field>
        </v-col>
        <v-col class="filter-col-class" cols="12" md="2">
          <v-text-field
            type="time"
            v-model="form.finish_time"
            label="Hora Final"
          ></v-text-field>
        </v-col>

        <!-- {{ value }} -->
        <v-col class="filter-col-class" cols="12">
          <v-select
            v-model="form.status"
            :items="items"
            attach
            chips
            label="Status"
            multiple
            dense
            class="mt-2"
            item-text="name"
            item-value="value"
          ></v-select
        ></v-col>
        <v-col cols="3">
          <v-switch
            color="amber darken-4"
            v-model="show_product_detail"
            label="Detalhar Produtos"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="amber darken-4"
            v-model="show_people_detail"
            label="Trazer Informações sobre compradores"
          ></v-switch>
        </v-col>
        <v-col class="filter-col-class" cols="13" md="3" align="right">
          <v-btn @click="callReport()" color="green darken-2 white--text mb-5"
            >Filtrar</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  methods: {
    ...mapActions("OrderReport", ["showReport"]),
    ...mapActions("Auth", ["checkLoged"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    callReport() {
      this.checkLoged();
      let data = {};
      data = {
        init_date: `${this.form.init_only_date} ${this.form.init_time}`,
        finish_date: `${this.form.finish_only_date} ${this.form.finish_time}`,
        ...this.form,
        show_product_detail: this.show_product_detail ? true : false,
        show_people_detail: this.show_people_detail ? true : false,
      };
      this.showReport(data);
    },
  },
  computed: {
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  components: {},
  data: function () {
    return {
      adm: false,
      show_product_detail: false,
      form: {
        init_only_date: new Date(),
        finish_only_date: null,
        init_time: "00:00",
        finish_time: "23:59",
        status: null,
        // show_product_detail: false,
      },
      items: [
        { value: 0, name: "Pendente" },
        { value: 1, name: "Pgto em Analise" },
        { value: 2, name: "Separação" },
        { value: 3, name: "Transito" },
        { value: 4, name: "Entregue" },
        { value: 5, name: "Expirado" },
        { value: 6, name: "Cancelado" },
        { value: 7, name: "Pgto Recusado" },
      ],
    };
  },
  mounted() {
    // let currentTime = new Date();
    // console.log(currentTime.getDate());
    // this.form.init_only_date = `01-${currentTime.getMonth()}-${currentTime.getFullYear()}`;
    this.form.init_only_date = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    )
      .toISOString()
      .slice(0, 10);
    console.log(this.form.init_only_date);
    this.form.finish_only_date = new Date().toISOString().slice(0, 10);
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
  },
};
</script>

<style scoped>
.main-class {
  background: white !important;
  height: 100% !important;
}

.filter-class {
  padding: 25px 30px 0 30px;
}
.filter-col-class {
  padding-bottom: 0px !important;
}

@media screen and (max-width: 960px) {
  .main-relatorio {
    height: 100% !important;
  }
}
</style>