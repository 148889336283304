import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
// import { v4 as uuidv4 } from 'uuid';


import { toastAlert } from "@/utils/Alerts/toast";

const gettoken = async function (payload) {
    let companyId = payload.company_id;

    try {
        let res = await axios.post(`${baseApiUrl}/integration/${companyId}/get-token?integrator=${payload.key_name}`);
        if (res.status === 200) {
            return res.data;
        } else {
            console.error('Error in response:', res.status);
            return null;
        }
    } catch (e) {
        if (e.response && e.response.status === 422) {
            console.error('Validation Error:', e.response.data.errors);
        } else {
            console.error('Error:', e);
        }
        return null;
    }
};


export const save = async function ({ commit, state }, payload) {
    commit('SET_ERRORS', null);
    let token = generateRandomToken();
    let companyId = getCompanyId({ state });

    const data = {
        key_name: payload.key_name,
        value: token,
        company_id: companyId,
        ...payload
    };

    spinnerChoice('full', true);

    let token_saved = await gettoken(payload);

    if (token_saved) {
        data.value = token_saved;
    }
    let link = createLinkIntegrator(data);

    try {
        let res = await axios.post(`${baseApiUrl}/integration-key-value/save`, data);

        if (res.data.status === 201) {
            commit('SET_ERRORS', '');

            sessionStorage.setItem("toastMsg", `Token foi cadastrado com sucesso!`);
            toastAlert('success');

            commit('SET_DIALOG', true);
            commit('SET_LINK_API', link);
        } else {
            commit('SET_ERRORS', res.data.errors);
            console.error(state.errors);
        }
    } catch (e) {
        if (e.response && e.response.status === 422 && token_saved) {
            commit('SET_ERRORS', e.response.data.errors);
            commit('SET_DIALOG', true);
            commit('SET_LINK_API', link);
        }
        if (e.response && e.response.status === 422) {
            commit('SET_ERRORS', e.response.data.errors);
            commit('SET_LINK_API', link);
        } else {
            catchError(e.status);
        }
    } finally {
        spinnerChoice('full', false);
    }
};

const createLinkIntegrator = function (payload) {
    let integrator = getIntegratorName(payload.key_name);
    let link = baseApiUrl + '/integration/stock/' + payload.company_id + '/update' + '?' + 'integrator=' + integrator + '&' + 'token=' + payload.value;
    return link;

}

const generateRandomToken = function () {
    var rand = function () {
        return Math.random().toString(36).substr(2); // remove `0.`
    };

    var token = function () {
        return rand() + rand() + rand() + rand() + rand() + rand() + rand() + rand() + rand(); // to make it longer
    };

    return token();

}

const getIntegratorName = function (key_name) {
    switch (key_name) {
        case 'bling_token':
            return 'bling';
        case 'global_stock_token':
            return 'global'
        case 'progrid_token':
            return 'progrid'
        default:
            return key_name.replace('_token', '');
    }

}

// const show = function ({ commit }, payload) {

//     spinnerChoice('full', true)

//     axios
//         .post(`${baseApiUrl + defaultPath}/show`, payload)
//         .then(res => {
//             if (res.data[0].id) {
//                 commit('SET_FORMDATA', res.data[0]);
//             }
//         })
//         .catch(e => {
//             catchError(e);
//         })
//         .finally(() => {

//             spinnerChoice('full', false)

//         })
// }

// const cleanItem = function ({ commit }) {
//     commit('SET_FORMDATA', listDefault)
// }

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const getCompanyId = function ({ state }) {
    let companyId;
    if (JSON.parse(localStorage.getItem("user")).admin == false) {
        companyId = JSON.parse(localStorage.getItem("user")).company_id
    } else {
        companyId = state.id_company
    }

    return companyId;
}


export default {
    save, cleanErrors

}
