export default {
    dialog: false,
    spinner: false,
    errors: false,
    pvId: null,
    active: false,
    tokenId: null,
    pagarmeV5Status: null,
    company_id: null,
}
