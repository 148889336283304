<template>
  <span v-if="data.mostSales">
    <!-- Título Principal -->
    <v-row>
      <v-col cols="12">
        <h1 class="dashboard-title">Dashboard de Vendas</h1>
      </v-col>
    </v-row>

    <!-- Barra de Filtro -->
    <v-row class="filter-bar" dense>
      <!-- Data Inicial -->
      <v-col cols="12" md="6" lg="3">
        <v-select
          :items="months"
          label="Mês Inicial"
          v-model="startMonth"
          outlined
          dense
          class="filter-select"
          prepend-icon="mdi-calendar-month"
          @change="filterSales"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6" lg="3">
        <v-select
          :items="years"
          label="Ano Inicial"
          v-model="startYear"
          outlined
          dense
          class="filter-select"
          prepend-icon="mdi-calendar-range"
          @change="filterSales"
        ></v-select>
      </v-col>

      <!-- Data Final -->
      <v-col cols="12" md="6" lg="3">
        <v-select
          :items="months"
          label="Mês Final"
          v-model="endMonth"
          outlined
          dense
          class="filter-select"
          prepend-icon="mdi-calendar-month"
          @change="filterSales"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6" lg="3">
        <v-select
          :items="years"
          label="Ano Final"
          v-model="endYear"
          outlined
          dense
          class="filter-select"
          prepend-icon="mdi-calendar-range"
          @change="filterSales"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Bloco de Estatísticas -->
    <v-row>
      <v-col cols="6" md="3">
        <v-card class="stat-card green-card">
          <div class="stat-header">Total de Vendas</div>
          <div class="stat-value">{{ data.sells }}</div>
          <!-- <v-btn
            small
            color="primary"
            class="mt-2"
            @click="showUnfinishedDetails"
          >
            Ver mais detalhes
          </v-btn> -->
        </v-card>
      </v-col>

      <v-col cols="6" md="3">
        <v-card class="stat-card orange-card">
          <div class="stat-header">Vendas Hoje</div>
          <div class="stat-value">{{ data.todaySells }}</div>
          <!-- <v-btn
            small
            color="primary"
            class="mt-2"
            @click="showUnfinishedDetails"
          >
            Ver mais detalhes
          </v-btn> -->
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="stat-card gray-card larger-card">
          <div class="stat-header">Pedidos Pendentes</div>
          <div class="stat-value">{{ data.pendingSells }}</div>
          <!-- <v-btn color="primary" @click="showPendingDetails">
            Ver Detalhes das Categorias
          </v-btn> -->
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="stat-card red-card larger-card">
          <div class="stat-header">Pedidos Não Finalizados</div>
          <div class="stat-value">{{ data.unfinished }}</div>
          <!-- <v-btn color="primary" @click="showUnfinishedDetails">
            Ver Detalhes das Categorias
          </v-btn> -->
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogVisible" max-width="600px">
      <v-card>
        <v-card-title>
          <span v-if="dialogType === 'categories'"
            >Detalhes das Categorias</span
          >
          <span v-else-if="dialogType === 'pending'">
            Detalhes dos Pedidos Pendentes
          </span>
          <span v-else>Detalhes dos Pedidos Não Finalizados</span>
        </v-card-title>
        <v-card-text>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="mb-2"
          ></v-progress-circular>

          <v-data-table
            v-else
            :headers="detailHeaders"
            :items="detailData"
            dense
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogVisible = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Estatísticas Adicionais -->
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="card-default-dashboard">
          <v-row align="center">
            <v-col cols="6">
              <h2>{{ data.total_products }}</h2>
              <p>Total de Produtos</p>
            </v-col>
            <v-col cols="6">
              <i class="fas fa-tshirt icon-dashboard"></i>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card class="card-default-dashboard">
          <v-row align="center">
            <v-col cols="6">
              <h2>{{ data.users }}</h2>
              <p>Novos Usuários</p>
            </v-col>
            <v-col cols="6">
              <i class="fas fa-users icon-dashboard"></i>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Gráficos -->
    <v-row>
      <!-- Distribuição de Vendas -->
      <v-col cols="12" md="6" class="d-flex">
        <v-card class="chart-card flex-grow-1">
          <h3 class="table-title">Distribuição de Vendas</h3>
          <apexchart
            type="donut"
            :options="chartOptions"
            :series="chartData"
            class="chart-container"
          ></apexchart>
        </v-card>
      </v-col>

      <!-- Top 10 Produtos Mais Vendidos -->
      <v-col cols="12" md="6" class="d-flex">
        <v-card class="chart-card flex-grow-1">
          <h3 class="table-title">Top 10 Produtos Mais Vendidos</h3>
          <v-data-table
            :headers="mostSalesHeaders"
            :items="formattedMostSales"
            dense
            disable-pagination
            class="table-container"
          >
            <template v-slot:[`item.percentSales`]="{ item }">
              {{ item.percentSales }}%
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn small color="primary" @click="viewProductDetails(item)">
                Detalhes
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios"; // Para realizar a chamada POST
import { mapState, mapActions } from "vuex";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      years: Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i),
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      chartWidth: "100%",
      chartHeight: 400,
      chartOptions: {
        colors: ["#4caf50", "#fb8c00", "#9e9e9e", "#e53935"],
        labels: ["Total Vendas", "Vendas Hoje", "Pendentes", "Não Finalizados"],
        chart: {
          type: "donut",
          height: "100%",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex] + " vendas";
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              chart: {
                width: "90%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartData: [],
      dialogVisible: false,
      dialogType: null,
      loading: false,
      detailHeaders: [
        { text: "ID", value: "id" },
        { text: "Cliente", value: "customer" },
        { text: "Status", value: "status" },
        { text: "Data", value: "date" },
      ],
      detailData: [],
      mostSalesHeaders: [
        { text: "Nome do Produto", value: "name", align: "start" },
        { text: "Quantidade Vendida", value: "total", align: "center" },
        {
          text: "Percentual de Vendas",
          value: "percentSales",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState("Dashboard", ["data"]),
    formattedMostSales() {
      if (!this.data.mostSales || this.data.sells === 0) return [];
      return this.data.mostSales.map((product) => {
        const cleanName = product.name.replace(/\/Images\/.*/, ""); // Remove informações irrelevantes
        const percentSales = ((product.total / this.data.sells) * 100).toFixed(
          2
        ); // Calcula o percentual de vendas
        return {
          ...product,
          name: cleanName,
          percentSales, // Adiciona o percentual de vendas
        };
      });
    },
  },
  methods: {
    ...mapActions("Dashboard", ["loadChartData"]),

    async fetchDetails(type) {
      this.dialogType = type;
      this.dialogVisible = true;
      this.loading = true;

      try {
        const response = await axios.post(
          "http://127.0.0.1:8000/api/dashboard/home"
        );
        console.log("Dados da API:", response.data);

        if (type === "categories") {
          this.detailData = response.data.categorys || [];
        } else if (type === "pending") {
          this.detailData = response.data.pendingSells || [];
        } else if (type === "unfinished") {
          this.detailData = response.data.unfinished || [];
        }
      } catch (error) {
        console.error("Erro ao buscar detalhes:", error);
        this.detailData = [];
      } finally {
        this.loading = false;
      }
    },

    showCategoryDetails() {
      this.fetchDetails("categories");
    },

    async filterSales() {
      if (this.startMonth && this.startYear && this.endMonth && this.endYear) {
        const startMonthIndex = this.months.indexOf(this.startMonth) + 1;
        const endMonthIndex = this.months.indexOf(this.endMonth) + 1;

        try {
          this.loading = true;
          const response = await this.loadChartData({
            startMonth: startMonthIndex,
            startYear: this.startYear,
            endMonth: endMonthIndex,
            endYear: this.endYear,
          });
          if (response) {
            this.data.total_products = response.total_products || 0;
            this.data.users = response.users || 0;
          }
          this.updateChart();
        } catch (error) {
          console.error("Erro ao filtrar vendas:", error);
        } finally {
          this.loading = false;
        }
      }
    },
    updateChart() {
      this.chartData = [
        this.data.sells || 0,
        this.data.todaySells || 0,
        this.data.pendingSells || 0,
        this.data.unfinished || 0,
      ];
    },
    async initializeData() {
      const currentMonthIndex = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      this.startMonth = this.months[currentMonthIndex];
      this.startYear = currentYear;
      this.endMonth = this.months[currentMonthIndex];
      this.endYear = currentYear;

      await this.filterSales();
    },
    updateChartSize() {
      const width = window.innerWidth;
      if (width < 768) {
        this.chartWidth = "100%";
        this.chartHeight = 300;
      } else if (width < 1024) {
        this.chartWidth = "90%";
        this.chartHeight = 350;
      } else {
        this.chartWidth = "600px";
        this.chartHeight = 400;
      }
    },
    showPendingDetails() {
      this.fetchDetails("pending");
    },
    showUnfinishedDetails() {
      this.fetchDetails("unfinished");
    },
  },
  async mounted() {
    await this.initializeData();
    this.updateChartSize();
    window.addEventListener("resize", this.updateChartSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateChartSize);
  },
  watch: {
    data: {
      handler() {
        this.updateChart();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.dashboard-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.filter-bar {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-card {
  text-align: center;
  border-radius: 8px;
  padding: 20px;
  color: white;
  font-weight: bold;
}

.green-card {
  background-color: #4caf50;
}

.orange-card {
  background-color: #fb8c00;
}

.gray-card {
  background-color: #9e9e9e;
}

.red-card {
  background-color: #e53935;
}

.stat-header {
  font-size: 16px;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 28px;
}

.icon-dashboard {
  font-size: 50px;
  color: #f57c00;
}

.card-default-dashboard {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/*
Gráfico
*/

.chart-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Garante que o cartão preencha toda a altura disponível */
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
}
.table-container {
  max-height: 400px; /* Limita a altura da tabela */
  overflow-y: auto; /* Adiciona barra de rolagem, se necessário */
}
.chart-container {
  flex-grow: 1; /* Faz com que o gráfico se ajuste à altura do cartão */
  width: 100%;
  height: 100%; /* Garante que o gráfico ocupa toda a altura do cartão */
}

.table-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/*
Filtro
*/

.filter-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.filter-title {
  font-size: 20px;
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 10px;
}

.filter-bar {
  margin-top: 15px;
}

.filter-select {
  margin-bottom: 15px;
}

.filter-button-container {
  text-align: center;
}

.filter-button {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
}

.chart-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.chart-card {
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
}

@media (max-width: 960px) {
  .stat-card {
    margin-bottom: 10px;
  }
  .icon-dashboard {
    font-size: 40px;
  }
  .chart-container {
    height: 300px;
  }
}
@media (max-width: 768px) {
  .chart-container {
    max-width: 100%; /* Em telas menores, ocupa toda a largura */
  }
}
@media (max-width: 600px) {
  .chart-container {
    height: 250px;
  }

  .table-title {
    font-size: 16px;
  }

  .chart-card {
    padding: 15px;
  }
}
</style>
