import Vue from 'vue'
import Vuex from 'vuex'

import Spinner from '@/Layouts/Components/store'
import Company from '@/views/Administration/Pages/Company/store'
import User from '@/views/Administration/Pages/User/store'
import Adress from '@/views/Administration/Pages/Company/components/Adress/store'
import Phone from '@/views/Administration/Pages/Company/components/Phones/store'
import Definition from '@/views/ProductSession/Pages/Definition/store'
import Category from '@/views/ProductSession/Pages/Category/store'
import Product from '@/views/ProductSession/Pages/Product/store'
import ProductGroup from '@/views/ProductSession/Pages/ProductGroup/store'
import Brand from '@/views/ProductSession/Pages/Brand/store'
import Coupon from '@/views/RecordSession/Pages/Coupon/store'
import Auth from '@/http/auth/store'
import Grid from '@/views/ProductSession/Pages/Product/Grid/store'
import ProductDiscountByPaymentMethod from '@/views/ProductSession/Pages/Product/ProductDiscountByPaymentMethod/store'
import ProductImage from '@/views/ProductSession/Pages/Product/Image/store'
import Promotion from '@/views/ProductSession/Pages/Product/Promotion/store'
import SubCategory from '@/views/ProductSession/Pages/SubCategory/store'
import ReceiveData from '@/views/StoreSettings/Pages/ReceiveData/store'
import PendingBuys from '@/views/OrderSession/pages/PendingBuys/store'
import SortingOutBuys from '@/views/OrderSession/pages/SortingOutBuys/store'
import CancelOrder from '@/views/OrderSession/pages/CancelOrder/store'
import InTransitBuys from '@/views/OrderSession/pages/InTransitBuys/store'
import orderListDefault from '@/views/OrderSession/pages/orderListDefault/store'
import DetailOrder from '@/views/OrderSession/pages/DetailOrder/store'
import DeliverySettings from '@/views/CompanySettings/Pages/DeliverySettings/store'
import DeliveryRestriction from '@/views/CompanySettings/Pages/DeliverySettings/Components/DeliveryRestriction/store'
import ReceiveDataRule from '@/views/StoreSettings/Pages/ReceiveData/Components/ReceiveDataRule/store'
import Dashboard from '@/views/Main/Dashboard/store'
import AtributeGroup from '@/views/ProductSession/Pages/AtributeGroup/store'
import Atribute from '@/views/ProductSession/Pages/Atribute/store'
import ProductAtribute from "@/views/ProductSession/Pages/Product/ProductAtribute/store";
import Reports from "@/views/Reports/store";
import OrderReport from "@/views/Reports/Pages/Order/store";
import FacebookCatalogReport from "@/views/Reports/Pages/FacebookCatalog/store";
import Intrermediary from "@/views/CompanySettings/Pages/DeliverySettings/Components/Intermediaries/store";
import RedeIntrermediary from "@/views/StoreSettings/Pages/ReceiveData/Components/Rede/store";
import PaymentSlip from "@/views/StoreSettings/Pages/ReceiveData/Components/PaymentSlip/store";
import VariationGroup from "@/views/ProductSession/Pages/VariationGroup/store";
import VariationProductGroup from "@/views/ProductSession/Pages/VariationGroup/VariationProductGroup/store";
import CustomerComment from "@/views/EcommerceAdministration/Pages/CustomerComment/store";
import LayoutOrganization from '@/views/LayoutOrganization/Pages/LayoutOrganization/store';
import NavBarPersonalization from '@/views/LayoutPersonalization/Pages/NavbarOrganization/store';
import BannerPersonalization from '@/views/LayoutPersonalization/Pages/BannerOrganization/store';
import StockIntegration from '@/views/Integration/Stock/Pages/StockIntegration/store';
import MarketingFacebookIntegration from '@/views/Integration/Marketing/Pages/Facebook/store';
import IntegrationSettings from '@/views/Integration/Settings/Pages/Settings/store';
import MarketingGoogleIntegration from '@/views/Integration/Marketing/Pages/Google/store';
import PagarmeIntermediary from "@/views/StoreSettings/Pages/ReceiveData/Components/Pagarme/store";

import Users from "@/views/EcommerceAdministration/Pages/Users/store";
import ContactList from "@/views/EcommerceAdministration/Pages/ContactList/store";


import Utils from '@/utils/store'

Vue.use(Vuex)

const modules = {
    ProductAtribute,
    orderListDefault,
    ReceiveData,
    Promotion,
    CancelOrder,
    SubCategory,
    ProductImage,
    Grid,
    ProductDiscountByPaymentMethod,
    Utils,
    Auth,
    Spinner,
    Company,
    User,
    Adress,
    Phone,
    Definition,
    Category,
    Product,
    Brand,
    Coupon,
    PendingBuys,
    SortingOutBuys,
    InTransitBuys,
    DetailOrder,
    DeliverySettings,
    DeliveryRestriction,
    ReceiveDataRule,
    Dashboard,
    AtributeGroup,
    Atribute,
    Reports,
    OrderReport,
    FacebookCatalogReport,
    Intrermediary,
    RedeIntrermediary,
    ProductGroup,
    PaymentSlip,
    VariationGroup,
    VariationProductGroup,
    CustomerComment,
    LayoutOrganization,
    NavBarPersonalization,
    BannerPersonalization,
    StockIntegration,
    Users,
    MarketingFacebookIntegration,
    IntegrationSettings,
    MarketingGoogleIntegration,
    PagarmeIntermediary,
    ContactList
}

export default new Vuex.Store({
    modules
})