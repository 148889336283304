<template>
  <v-card class="default-table">
    <v-container>
      <v-data-iterator
        :items="list.data"
        items-per-page.sync="4"
        hide-default-footer
      >
        <template v-slot:header>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                :items="[25, 50, 100]"
                @input="setList()"
                v-model="pagination.itemsPerPage"
                style="max-width: 150px"
              >
                <template v-slot:prepend>
                  <small>Ítens por Página: </small>
                </template>
              </v-select>
            </v-col>
            <v-col cols="5" class="d-none d-sm-block" />
            <v-col cols="12" sm="3">
              <small>Total de Itens: {{ list.total }}</small>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-pagination
                class="mobile-pagination"
                v-model="page"
                @input="setList()"
                :length="list.last_page"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>

        <template v-slot:default>
          <v-row>
            <v-col
              v-for="(item, index) in list.data"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="sub-header">
                  <div class="sub-header-text">
                    {{ item.id }} - {{ item.name }}
                  </div>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Ref:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      {{ item.ref }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="item-row">
                    <v-list-item-content>Categoria:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      {{ item.category }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="item-row" v-if="checkUser()">
                    <v-list-item-content>Cliente:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      {{ item.company_name }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="item-row">
                    <v-list-item-content>Valor:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      R$ {{ item.price.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Botão Ativo -->
                  <v-list-item class="item-row">
                    <v-list-item-content>Ativo:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      <v-switch
                        v-model="item.active"
                        color="amber darken-4"
                        hide-details
                        @change="updateActive(item)"
                      ></v-switch>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="item-row">
                    <v-list-item-content>Estoque:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      <v-chip :class="negativeStock(item.balance)">
                        {{ item.balance }}
                      </v-chip>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="item-row">
                    <v-list-item-content>Relevância:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      {{ item.relevance }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" align="right">
                          <v-btn  color="#272727" @click="edit(item)">
                            <v-icon color="white">mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>


<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";

export default {
  data: function () {
    return {
      currentHeader: "",
    };
  },
  props: {
    list: {},
  },
  computed: {
    ...mapState("Product", ["pagination"]),
    itemsPerPage: {
      get() {
        return this.$store.state.Product.pagination.itemsPerPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
  },
  methods: {
    ...mapActions("Product", ["setList", "update"]),

    checkUser() {
      return JSON.parse(localStorage.getItem("user")).admin;
    },

    updateActive(item) {
      const payload = {
        id: item.id,
        active: item.active,
        name: item.name,
      };
      this.update(payload)
        .then(() => {
          toastAlert("success", "Status do produto atualizado com sucesso!");
        })
        .catch(() => {
          toastAlert("error", "Erro ao atualizar o status do produto.");
        });
    },

    negativeStock(value) {
      return value < 1 ? "negative-stock-class" : "stock-class";
    },

    edit(item) {
      this.$router.push(`form-product/${item.id}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>


<style scoped>
.mobile-pagination >>> .v-pagination__item {
  min-width: 0 !important;
  width: 25px !important;
  height: 25px !important;
}

.sub-header-text {
  font-size: 16px !important;
  font-weight: bold;
  height: 34px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
}

.item-row {
  border-top: 1px solid lightgray;
}

.item-text {
  height: 64px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.stock-class {
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.negative-stock-class {
  background: red !important;
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
}
</style>