import ReceiveDataForm from '@/views/StoreSettings/Pages/ReceiveData/form'
import ReceiveDataList from '@/views/StoreSettings/Pages/ReceiveData/list'

const routes = [
    {
        path: '/receive-data',
        props: true,
        component: ReceiveDataList,
    },
    {
        path: '/form-receive-data',
        props: true,
        component: ReceiveDataForm,
        children: [
            { path: ':id', component: ReceiveDataForm, props: true, }
        ]
    },
]


export default routes