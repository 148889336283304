<template>
  <admin-blade
    :iconBtnTop="removeButtonIfNotAdmin(adm)"
    routeBtnTop="/receive-data"
  >
    <span>
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
      
        <!-- Opcoes para Formulario -->
        <span></span>
        <!-- Opcoes para Formulario -->
        <v-row>
          <v-col sm="3" cols="12" v-if="adm">
            <v-autocomplete
              v-model="formData.company_id"
              :items="companyList"
              item-text="fantasy_name"
              item-value="id"
              label="Loja *"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.company_id }}</strong>
          </v-col>
          <v-col sm="3" cols="12">
            <v-autocomplete
            v-model="formData.intermediary"
            :items="intermediatories"
            @change="checkIntermediaryAndSave"
            item-text="name"
            item-value="value"
            label="Intermediador *"
            color="amber darken-4"
          />          
          <!-- <v-btn @click="teste()">teste</v-btn> -->
            <strong class="red--text">{{ errors.intermediary }}</strong>
          </v-col>

          <v-col sm="2" cols="12">
            <v-autocomplete
              v-model="formData.installments"
              :items="installments"
              item-text="value"
              item-value="value"
              label="Parcelas *"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.installments }}</strong>
          </v-col>
          <v-col sm="3" cols="12">
            <label for=""> Valor min por Parcela </label>
            <money
              class="money"
              v-bind="money"
              v-model.lazy="formData.minimum_installment_value"
            ></money>
            <strong class="red--text">{{
              errors.minimum_installment_value
            }}</strong>
          </v-col>

          <v-col sm="4" cols="12">
            <v-text-field
              v-model="formData.email"
              label="Email p/ recebimento dos pedidos"
            ></v-text-field>
            <strong class="red--text">{{ errors.email }}</strong>
          </v-col>
          <v-col cols="12">
            <rede-form
              v-if="formData.intermediary == 'rede'"
              :companyId="formData.company_id"
            />

            <pagarme-form
              v-if="formData.intermediary == 'pagarme'"
              :companyId="formData.company_id"
            />
            <new-pagarme-form
              v-if="formData.intermediary == 'pagarmev5'"
              :companyId="formData.company_id"
            />
          </v-col>
          <v-col v-if="formData.intermediary == 'cielo'" sm="4" cols="12">
            <v-text-field
              v-model="formData.soft_descriptor"
              label="Descrição Fatura"
            ></v-text-field>
            <p class="no-padding grey--text">
              Nota: Descrição Fatura max 13 caracteres, (Somente Master e Visa )
            </p>
            <strong class="red--text">{{ errors.soft_descriptor }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="formData.intermediary == 'cielo'" cols="6">
            <v-textarea
              label="Chave Comerciante"
              v-model="formData.merchant_key"
            ></v-textarea>
            <strong class="red--text">{{ errors.merchant_key }}</strong>
          </v-col>
          <v-col v-if="formData.intermediary == 'cielo'" cols="6">
            <v-textarea
              label="ID Comerciante"
              v-model="formData.merchant_id"
            ></v-textarea>
            <strong class="red--text">{{ errors.merchant_id }}</strong>
          </v-col>
          <v-col cols="4">
            <v-switch
              color="amber darken-4"
              v-model="formData.send_client_mail"
              label="Enviar Email p/ Cliente"
            ></v-switch>
          </v-col>
        </v-row>
        <h3>Boleto</h3>
        <hr />
        <payment-slip
          :intermediary="formData.payment_slip_intermediary"
          v-if="formData.payment_slip && formData.id"
        />
        <v-row>
          <v-col cols="4">
            <v-switch
              color="amber darken-4"
              v-model="formData.payment_slip"
              label="Receber Boleto"
            ></v-switch>
          </v-col>
          <v-col sm="3" cols="12">
            <v-autocomplete
              v-if="formData.payment_slip"
              v-model="formData.payment_slip_intermediary"
              :items="paymentSlipIntermediatories"
              item-text="name"
              item-value="value"
              label="Intermediador *"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{
              errors.payment_slip_intermediary
            }}</strong>
          </v-col>
        </v-row>

        <h3>Configuração Recebimento</h3>
        <hr />
        <v-row>
          <v-col cols="4">
            <v-switch
              color="amber darken-4"
              v-model="formData.capture_automatic"
              label="Captura Automatica"
            ></v-switch>
          </v-col>
          <v-col cols="4">
            <v-switch
              color="amber darken-4"
              v-model="formData.antifraud"
              label="Antifraude"
            ></v-switch>
          </v-col>
          <v-col cols="4">
            <v-switch
              color="amber darken-4"
              v-model="formData.capture_low_risk"
              label="Captura Baixo Risco"
            ></v-switch>
          </v-col>
          <v-col cols="4">
            <v-switch
              color="amber darken-4"
              v-model="formData.void_high_risk"
              label="Recusar Alto Risco"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="4" cols="12">
            <v-text-field
              v-model="extra_info_receive.pix"
              label="Chave Pix para Recebimento"
            ></v-text-field>
            <strong class="red--text">{{ errors.pix }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" cols="12">
            <v-autocomplete
              v-model="formData.antifraud_service"
              :items="intermediatoriesAntifraud"
              item-text="name"
              item-value="value"
              label="Análise Antifraude Terceiro"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.antifraud_service }}</strong>
          </v-col>
          <v-col sm="3" cols="12">
            <v-autocomplete
              v-model="formData.api_version"
              :items="apiVersionList"
              item-value="value"
              item-text="name"
              label="Versão API"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.api_version }}</strong>
          </v-col>
        </v-row>

        <h3>Configuração Regras Recebimento</h3>
        <hr />
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="formData.payment_rule_to_consider"
              :items="[
                { value: 'buy', name: 'Por Regra/Exceção de Pgto' },
                { value: 'product', name: 'Por Regra de Produto' },
              ]"
              item-text="name"
              item-value="value"
              label="Regra de Pagamento a Ser Considerada"
              color="amber darken-4"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!-- Default -->
        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(removeEmailIfNull(), formatedExtraInfo())"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formatedExtraInfo(), formData)"
          >Salvar</v-btn
        >
      </v-form>
      <div v-if="formData.id" class="default-form mt-4">
        <receive-data-rule :receiveDataId="this.$route.params.id" />
      </div>
    </span>
  </admin-blade>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import RedeForm from "@/views/StoreSettings/Pages/ReceiveData/Components/Rede/RedeForm";
import PagarmeForm from "@/views/StoreSettings/Pages/ReceiveData/Components/Pagarme/PagarmeForm";
import NewPagarmeForm from "@/views/StoreSettings/Pages/ReceiveData/Components/Pagarme/NewPagarmeForm";
import PaymentSlip from "@/views/StoreSettings/Pages/ReceiveData/Components/PaymentSlip/paymentSlipComponent";
import ReceiveDataRule from "@/views/StoreSettings/Pages/ReceiveData/Components/ReceiveDataRule/list";
import { mapState, mapActions } from "vuex";
import { Money } from "v-money";

export default {
  components: {
    AdminBlade,
    RedeForm,
    ReceiveDataRule,
    PaymentSlip,
    Money,
    PagarmeForm,
    NewPagarmeForm,
  },
  data: function () {
    return {
      money: {},
      valid: true,
      adm: false,
      installments: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
        { value: 11 },
        { value: 12 },
      ],
    };
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("ReceiveData", [
      "errors",
      "formData",
      "intermediatories",
      "intermediatoriesAntifraud",
      "paymentSlipIntermediatories",
      "apiVersionList",
      "extra_info_receive",
    ]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    removeEmailIfNull() {
      if (this.formData.email == null || this.formData.email == undefined) {
        delete this.formData.email;
      }
      return this.formData;
    },
    teste(){
        this.formData.intermediary = "pagarmev5";
        this.formData.api_version = 5;
        return;
   
    },
    formatedExtraInfo() {
      // Define o valor padrão para installments se estiver vazio ou nulo
      if (!this.formData.installments) {
        this.formData.installments = 1;
      }

      if (this.extra_info_receive.pix !== null) {
        this.formData.extra_receive_info = this.extra_info_receive;
      }
      return this.formData;
    },

    ...mapActions("Company", {
      setCompany: "setList",
    }),
    removeButtonIfNotAdmin(value) {
      if (value == true) {
        return "fas fa-file-invoice-dollar";
      }
    },

    checkIntermediaryAndSave() {
    this.checkIntermediary(); // Atualiza a lógica de intermediário

    if (this.formData.intermediary === "pagarmev5") {
      // Simula o clique no botão "Salvar Alterações"
      this.$nextTick(() => {
        const saveButton = document.querySelector(".mr-4");
        if (saveButton) {
          saveButton.click();
          // console.log("Salvando automaticamente para pagarmev5.");
        } else {
          console.error("Botão de salvar não encontrado.");
        }
      });
    }
  },

  checkIntermediary() {
    if (this.$route.query.authorization_code) {
      this.formData.intermediary = "pagarmev5";
      this.formData.api_version = 5;
      return;
    }

    if (this.formData.intermediary === "pagarme" || this.formData.intermediary === "pagarmev5") {
      this.formData.api_version = 5;
    } else {
      this.formData.api_version = 1;
    }
  },

    ...mapActions("ReceiveData", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    // ...mapActions("", ["setList"]),
  },
  beforeMount(){

  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id});
    }
    // this.teste()
  // if (this.$route.query.authorization_code) {
  //   this.formData.intermediary = "pagarmev5";
  //   this.formData.authorization_code = this.$route.query.authorization_code;
  //   this.formData.api_version = 5;
  // } 
  // this.checkIntermediary()
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>