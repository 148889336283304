<template>
  <span>
    <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-user">
      <v-row align="center" justify="space-between" class="header-row">
        <v-col cols="12" md="12">
          <h1 class="title-topo">Lista de administradores da Loja</h1>
        </v-col>
     
      </v-row>

      <v-row class="filters-row">
        <!-- Campo de Busca -->
        <v-col cols="12" md="6">
          <v-text-field
            class="custom-search"
            v-model="search"
            label="Buscar Produto"
            single-line
            hide-details
            outlined
            clearable
          >
            <template v-slot:prepend-inner>
              <v-icon color="grey darken-2">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <!-- Campo de Filtro -->
        <v-col cols="12" md="6">
          <v-select
            v-model="filterStatus"
            :items="filterOptions"
            item-text="text"
            item-value="value"
            label="Filtrar Usuários"
            class="custom-filter-select"
            single-line
            hide-details
            outlined
            clearable
          >
            <template v-slot:prepend-inner>
              <v-icon color="grey darken-2">mdi-filter-variant</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <!-- Tabela de Usuários -->
          <v-data-table
            class="default-table"
            :headers="headers"
            :items="filteredUsers"
            :search="search"
            :footer-props="footer"
            dense
          >
            <template v-slot:item.admin="{ item }">
              <v-chip
                :color="item.admin ? 'green' : 'red'"
                dark
                large
                class="chip-role"
              >
                {{ item.admin ? "Administrador" : "Usuário" }}
              </v-chip>
            </template>

            <template v-slot:item.active="{ item }">
              <v-switch
                color="success"
                v-model="item.active"
                @change="updateActive(item)"
              ></v-switch>
            </template>

            <!-- Coluna de Ações -->
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="edit(item.id)">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </admin-blade>
  </span>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import { mapActions, mapState } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";

export default {
  components: {
    AdminBlade,
  },
  data() {
    return {
      search: "",
      filterStatus: "todos", // Valor padrão do filtro
      filterOptions: [
        { value: "todos", text: "Todos os Usuários" },
        { value: "ativos", text: "Usuários Ativos" },
        { value: "inativos", text: "Usuários Inativos" },
      ],
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "NOME", value: "name", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        { text: "TIPO DE ACESSO", value: "admin", class: "black--text" },
        { text: "Ativo", value: "active", class: "black--text" },
        { text: "Cliente", value: "fantasy_name", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  computed: {
    ...mapState("User", ["list"]),
    ...mapState("User", ["userType", "errors", "formData"]),
    filteredUsers() {
      // Filtra os usuários com base no filtro e na busca
      let filtered = this.list;
      if (this.filterStatus === "ativos") {
        filtered = filtered.filter((user) => user.active);
      } else if (this.filterStatus === "inativos") {
        filtered = filtered.filter((user) => !user.active);
      }
      return filtered;
    },
  },
  methods: {  ...mapActions("User", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("User", ["save", "cleanItem", "cleanErrors", "show", "update"]),
    ...mapActions("User", ["setList"]),
    edit(itemId) {
      this.$router.push(`form-user/${itemId}`);
    },
    updateActive(item) {
      const payload = {
        id: item.id,
        active: item.active,
      };
      this.update(payload)
        .then(() => {
          toastAlert("success", "Status do usuário atualizado com sucesso!");
        })
        .catch((error) => {
          toastAlert("error", "Erro ao atualizar o status do usuário.");
          console.error(error);
        });
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>

<style scoped>
.chip-role {
  text-transform: uppercase;
  font-weight: bold;
  display: flex; /* Habilita o flexbox */
  align-items: center; /* Centraliza verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
  text-align: center;
  font-size: 14px; /* Tamanho do texto */
  width: 100%; /* Ocupa toda a largura da div */
  height: 30px; /* Define uma altura fixa */
  border-radius: 20px; /* Deixa as bordas arredondadas */
}
.default-search{
  text-align: left;
  font-size: 2rem; /* Tamanho maior para destaque */
  font-weight: bold; /* Negrito */
  color: #4caf50; /* Cor verde para destacar */
  margin: 0 0 10px 0; /* Espaçamento inferior */
  padding-left: 10px; /* Recuo para alinhamento */
  border-bottom: 2px solid #e0e0e0; /* Linha de destaque abaixo */  
  border-radius: 2em;
}
.admin-table-class {
  background: green;
  border-radius: 10px;
  padding: 5px;
  color: white;
  text-align: center;

}
.store-table-class {
  background: blue;
  border-radius: 10px;
  padding: 5px;
  color: white;
  text-align: center;

}

.filter-select{
  border-radius: 2em;
  
}
.v-chip {
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  
}
.title-topo{
  border-bottom: 0.5px solid black;
  display: flex; /* Habilita o flexbox */
  align-items: center; /* Centraliza verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
}
.custom-search {
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s;
}



.custom-search >>> .v-icon {
  font-size: 22px;
}

.custom-filter-select {
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s;
}

.custom-filter-select:hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-filter-select >>> .v-input__control {
  border-color: #bdbdbd;
}

.custom-filter-select >>> .v-icon {
  font-size: 22px;
}

</style>
