<template>
  <admin-blade iconBtnTop="mdi-bookmark" routeBtnTop="/sub-category">
    <span>
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
        <h1 class="title-topo">Formulario Sub Categoria</h1>

        <v-row>
          <v-col sm="3" cols="12" v-if="!this.$route.params.id">
            <v-autocomplete
              @change="defineCategory(formData.definition_id)"
              v-model="formData.definition_id"
              :items="definitionList"
              item-text="name"
              item-value="id"
              label="Segmento *"
              color="amber darken-4"
              :rules="rulesFunction('Segmento', 0)"
            ></v-autocomplete>
          </v-col>
          <v-col sm="3" cols="12">
            <v-autocomplete
              v-model="formData.category_id"
              :items="categoryList"
              item-text="name"
              item-value="id"
              label="Categoria *"
              color="amber darken-4"
              :rules="rulesFunction('Categoria', 0)"
            ></v-autocomplete>
          </v-col>
          <v-col sm="3" cols="12">
            <v-text-field v-model="formData.name" :rules="rulesFunction('Nome', 2)" label="Nome *"></v-text-field>
            <strong class="red--text">{{errors.name}}</strong>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
        >Salvar Alterações</v-btn>
        <v-btn
          v-else
          :disabled="!valid"
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
        >Salvar</v-btn>
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapState("SubCategory", ["userType", "errors", "formData"]),
    ...mapState("Category", {
      categoryList: "list"
    }),
    ...mapState("Definition", {
      definitionList: "list"
    })
  },
  methods: {
    ...mapActions("SubCategory", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update"
    ]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    ...mapActions("Definition", {
      setDefinition: "setList"
    }),
    ...mapActions("Category", {
      setCategory: "setFilteredList"
    }),
    ...mapActions("Category", {
      setAllCategory: "setList"
    }),
    defineCategory(definitionId) {
      this.setCategory(definitionId);
    }
  },
  mounted() {
    this.setDefinition();
    if (this.$route.params.id) {
      this.setAllCategory();
      this.show(this.$route.params.id);
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  }
};
</script>

<style>
</style>