export default {
    showInactive: 0,

    pagination: {
        itemsPerPage: 25,
        currentPage: 1,
    },

    order: {
        field: "",
        value: "",
    },

    filter: {
        productName: "",
        reference: "",
        definition: "",
        category: "",
        subcategory: "",
        client: "",

        grid: {
            showGrid: false,
            code: "",
            color: "",
            size: "",
        },
    },

    formData: {
        name: null,
        description: null,
        facebook_description: null,
        category_id: null,
        price: 0,
        gender: null,
        brand_id: null,
        id: null,
        created_at: null,
        updated_at: null,
        unique_stock: false,
        stock_control: true,
        show_img: false,
    },

    list: [],
    errors: [],
    deliveryRule: [
        { value: 0, name: "Nenhum" },
        { value: 1, name: "Por conta Comprador" },
        { value: 2, name: "Somente Tabela de Fretes" },
    ],

    exactSearchProducts: {
        product_name: null,
        ref: null,
        code: null,
        grid_id: null,
    },

    listExactProducts: [],

    companyId: null, // ID da empresa
    images: [], // Lista de imagens associadas à empresa
};
