<template>
  <admin-blade iconBtnTop="fas fa-cart-plus" routeBtnTop="/product">
    <span>
      <!-- <h1>TEste {{ productGroupList }}</h1> -->
      <v-form class="mt-5" ref="form" id="formProduct" v-model="valid">
        <div class="default-form" id="formProduct">
          <h3 class="title-topo title-mobile d-block d-sm-none">
            <v-icon class="mr-2">mdi-form-textbox</v-icon>
            Formulário Produto
          </h3>
          <h1 class="title-topo title-desktop d-none d-sm-block">
            <v-icon class="mr-3">mdi-form-textbox</v-icon>
            Formulário Produto
          </h1>

          <v-row>
            <v-col md="6" cols="12">
              <p v-if="formData.updated_at">
                <strong>Ultima Modificação:</strong>
                {{ formData.updated_at }}
              </p>
            </v-col>
            <v-col md="6" cols="12">
              <p v-if="formData.created_at">
                <strong>Data Cadastro:</strong>
                {{ formData.created_at }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12" md="12" cols="12" v-if="adm">
              <v-autocomplete
                v-model="formData.company_id"
                :items="companyList"
                item-text="fantasy_name"
                item-value="id"
                label="Loja *"
                @change="setProductGroupList(formData.company_id)"
                color="amber darken-4"
                :rules="rulesFunction('Loja', 0)"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.company_id }}</strong>
            </v-col>
          </v-row>

          <v-row>
          
            <v-col sm="6" md="4" cols="12">
              <v-text-field
                v-model="formData.name"
                :rules="rulesFunction('Nome', 2)"
                label="Nome do produto*"
              ></v-text-field>
              <strong class="red--text">{{ errors.name }}</strong>
            </v-col>

            <v-col sm="4" md="4" cols="12">
              <v-text-field
                v-model="formData.ref"
                label="Número de Referência*"
              ></v-text-field>
              <strong class="red--text">{{ errors.ref }}</strong>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                v-model="formData.relevance"
                :items="relevanceList()"
                item-text="value"
                item-value="value"
                label="Relevância"
                color="amber darken-4"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.relevance }}</strong>
            </v-col>

        
          </v-row>

          <v-row>
            <v-col sm="6" md="4" cols="12">
              <v-autocomplete
                @change="defineCategory(formData.definition_id)"
                v-model="formData.definition_id"
                :items="definitionList"
                item-text="name"
                item-value="id"
                label="Segmento *"
                color="amber darken-4"
                :rules="rulesFunction('Segmento', 0)"
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" cols="12">
              <v-autocomplete
                @change="setSubCategory(formData.category_id)"
                v-model="formData.category_id"
                :items="categoryList"
                item-text="name"
                item-value="id"
                label="Categoria *"
                color="amber darken-4"
                :rules="rulesFunction('Categoria', 0)"
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" cols="12">
              <v-autocomplete
                v-model="formData.subcategory_id"
                :items="subCategoryList"
                item-text="subcategory_name"
                item-value="id"
                label="Sub Categoria *"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
       
          

          
          </v-row>
          <v-row> 
            <v-col sm="5" md="4" cols="12">
              <v-autocomplete
                v-model="formData.product_groups_id"
                :items="groupList"
                item-text="name"
                item-value="id"
                label="Grupo *"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
           
            <v-col sm="6" md="4" cols="12">
              <v-autocomplete
                v-model="formData.brand_id"
                :items="brandList"
                item-text="name"
                item-value="id"
                label="Marca *"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>


            <v-col sm="4" md="4" cols="12">
              <v-autocomplete
                v-model="formData.gender"
                :items="genders"
                item-text="name"
                item-value="value"
                label="Genero *"
                color="amber darken-4"
                :rules="rulesFunction('Genero', 0)"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.gender }}</strong>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="5" md="4" cols="12" class="">
              <v-autocomplete
                v-model="formData.confection_deadline"
                :items="confectiondeadlineList()"
                item-text="value"
                item-value="value"
                label="Tempo Confecção (em dias)"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
            <v-col sm="5" md="4" cols="12" class="">
              <v-autocomplete
                v-model="formData.delivery_rule"
                :items="deliveryRule"
                item-text="name"
                item-value="value"
                label="Regra Frete"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="formData.id" sm="3" md="3" cols="6">
              <v-switch
                color="amber darken-4"
                v-model="formData.active"
                label="Ativo"
              ></v-switch>
            </v-col>
            <v-col sm="3" md="3" cols="6">
              <v-switch
                color="amber darken-4"
                v-model="formData.kids"
                label="Infantil"
              ></v-switch>
            </v-col>
            <v-col sm="3" md="3" cols="6" class="mt-1">
              <v-switch
                color="amber darken-4"
                v-model="formData.unique_stock"
                label="Produto sem Grade"
              ></v-switch>
            </v-col>
            <v-col sm="3" md="3" cols="6" class="mt-1">
              <v-switch
                color="amber darken-4"
                v-model="formData.stock_control"
                label="Controlar Estoque"
              ></v-switch>
            </v-col>
          </v-row>

          <br>

          <v-row class="custom-row">
            <v-col cols="12" class="custom-col">
              <label for="price-input" class="custom-label">
                Preço (R$)
              </label>
              <money
                id="price-input"
                :rules="rulesFunction('Preço', 3)"
                class="custom-money"
                v-bind="money"
                v-model.lazy="formData.price"
              ></money>
            </v-col>
          </v-row>

          <br>

          <v-row>
            <v-col cols="12">
              <h3 class="text-center mt-5">Dados do produto para cálculo de frete!</h3>
              <h3 class="red--text text-center">
                *Não Utilizar Pontos ou Virgulas*
              </h3>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.weight_packed"
                label="Peso(Gramas)*"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.length_packed"
                label="Comprimento(cm)*"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.width_packed"
                label="Largura(cm)*"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.height_packed"
                label="Altura(cm)*"
              ></v-text-field>
            </v-col>
          </v-row>

          <br>
          <br>

          <v-row class="mt-4">
            <v-col>
              <div class="custom-container">
                <h4 class="mb-3 text-primary d-flex align-center">
                  <v-icon class="mr-2">mdi-information-outline</v-icon>
                  A descrição de cada produto para o catálogo do Facebook deve:
                </h4>
                <ul class="custom-list">
                  <li>
                    <v-icon class="mr-2 text-success">mdi-check-bold</v-icon>
                    Conter texto simples.
                  </li>
                  <li>
                    <v-icon class="mr-2 text-success">mdi-check-bold</v-icon>
                    Ter entre 30 e cinco mil caracteres.
                  </li>
                  <li>
                    <v-icon class="mr-2 text-success">mdi-check-bold</v-icon>
                    Seguir a gramática, a pontuação e a ortografia corretas. Escreva frases no formato comum, ou seja, somente a primeira letra da primeira palavra deve estar em maiúsculo.
                  </li>
                </ul>
          
                <div class="custom-warning-box">
                  <h4 class="mt-4 mb-3 text-danger d-flex align-center">
                    <v-icon class="mr-2">mdi-alert-octagon</v-icon>
                    Atenção: as descrições do produto não devem incluir:
                  </h4>
                  <ul class="custom-list">
                    <li>
                      <v-icon class="mr-2 text-warning">mdi-close-circle</v-icon>
                      Texto em HTML ou links.
                    </li>
                    <li>
                      <v-icon class="mr-2 text-warning">mdi-close-circle</v-icon>
                      Conteúdo em Rich Text, como listas de itens ou vários parágrafos.
                    </li>
                    <li>
                      <v-icon class="mr-2 text-warning">mdi-close-circle</v-icon>
                      Pontuação em excesso, como “!!!”, ou descrições com todas as LETRAS MAIÚSCULAS ou minúsculas. As pessoas podem associar isso com spam ou marketing enganoso.
                    </li>
                    <li>
                      <v-icon class="mr-2 text-warning">mdi-close-circle</v-icon>
                      Informações de envio ou da empresa. Inclua esses dados nos campos adequados do produto.
                    </li>
                    <li>
                      <v-icon class="mr-2 text-warning">mdi-close-circle</v-icon>
                      Telefones ou endereços de email.
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
          <strong class="red--text mb-5 d-block text-center">{{ errors.description }}</strong>
          <br />
          
          <v-row class="mt-4">
            <v-col cols="12">
              <v-textarea
                :rules="rulesFunction('Descrição', 2)"
                label="Descrição"
                v-model="formData.description"
                outlined
                dense
                class="custom-textarea"
                placeholder="Insira a descrição principal aqui"
              ></v-textarea>
            </v-col>
          
            <v-col cols="12" class="mt-3">
              <v-textarea
                rows="2"
                label="Descrição (Opcional)"
                v-model="formData.optional_description"
                outlined
                dense
                class="custom-textarea"
                placeholder="Adicione uma descrição adicional, se necessário"
              ></v-textarea>
            </v-col>
          
            <v-col cols="12" class="mt-3">
              <v-textarea
                label="Descrição Catálogo Instagram"
                v-model="formData.facebook_description"
                outlined
                dense
                class="custom-textarea"
                placeholder="Descrição otimizada para o catálogo do Instagram"
              ></v-textarea>
            </v-col>
          </v-row>
          

        
          
         
        
      
       
    
          <v-row no-gutters>
            <v-col cols="12" :align="align">
              <v-btn
                v-if="formData.id"
                class="change-btn"
                @click="updateContent()"
                >Salvar Alterações</v-btn
              >
              <v-btn
                v-else
                :disabled="!valid"
                class="register-btn"
                @click="saveContent()"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <div
          v-if="formData.id && !formData.unique_stock"
          class="mt-2 default-form"
          id="formProduct"
        >
          <grid-list v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <image-list :companyId="formData.company_id" v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <promotion-list v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <product-atribute v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <payment-rules v-if="formData.id" />
        </div>
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import ProductAtribute from "@/views/ProductSession/Pages/Product/ProductAtribute/list";
import ImageList from "@/views/ProductSession/Pages/Product/Image/list";
import GridList from "@/views/ProductSession/Pages/Product/Grid/list";
import PromotionList from "@/views/ProductSession/Pages/Product/Promotion/list";
import PaymentRules from "@/views/ProductSession/Pages/Product/ProductDiscountByPaymentMethod/list";
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
import { Money } from "v-money";
// import { toastAlert } from "@/utils/Alerts/toast";

export default {
  components: {
    ImageList,
    AdminBlade,
    Money,
    GridList,
    PromotionList,
    ProductAtribute,
    PaymentRules,
  },
  watch: {
    productGroupList: function () {
      this.createGroupList();
    },
    routeId() {
      this.onload();
    },
    definitionList() {
      if (this.definitionList[0] && !this.$route.params.id) {
        this.formData.definition_id = this.definitionList[0].id;
        this.defineCategory(this.formData.definition_id);
      }
    },
  },
  data: function () {
    return {
      groupList: [],
      adm: false,
      valid: false,
      money: {},
    };
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    // ...mapGetters("ProductGroup", ["productGroupListWithNull"]),
    ...mapState("Product", ["userType", "errors", "formData", "deliveryRule"]),
    ...mapState("Company", {
      companyList: "list",
    }),
    ...mapState("Category", {
      categoryList: "list",
    }),
    ...mapState("ProductGroup", {
      productGroupList: "list",
    }),
    ...mapState("SubCategory", {
      subCategoryList: "list",
    }),
    ...mapState("Definition", {
      definitionList: "list",
    }),
    ...mapState("Brand", {
      brandList: "list",
    }),
    ...mapState("Utils", ["genders"]),

    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },
  methods: {
    createGroupList() {
      this.groupList = [...this.productGroupList, { name: "Nenhum", id: null }];
    },
  
    saveContent() {
      let test = true;
      if (this.formData.price <= 0) {
        test = confirm(
          "O Valor do Produto é Igual a 0 (Zero), Tem certeza que deseja Salvar mesmo assim? "
        );
      }
      if (this.formData.relevance == null) {
        this.formData.relevance = 0;
      }
      if (test) {
        this.save(this.formData).then(() => {
          // Redireciona para a página /product após salvar
          this.$router.push('/product');
        }).catch((error) => {
          // Trate o erro se necessário
          console.error('Erro ao salvar:', error);
        });
      }
    },
    updateContent() {
      if (this.formData.relevance == null) {
        this.formData.relevance = 0;
      }
      this.update(this.formData).then(() => {
        // Redireciona para a página /product após atualizar
        this.$router.push('/product');
      }).catch((error) => {
        // Trate o erro se necessário
        console.error('Erro ao atualizar:', error);
      });
    },
    confectiondeadlineList() {
      let response = [];
      let i = null;
      for (i = 0; i < 46; i++) {
        response.push({ value: i });
      }
      return response;
    },
    relevanceList() {
      let response = [];
      let i = null;
      for (i = 0; i < 1999; i++) {
        response.push({ value: i });
      }

      return response;
    },
    defineCategory(definitionId) {
      this.setCategory(definitionId);
    },
    ...mapActions("Product", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("Definition", {
      setDefinition: "setList",
    }),
    ...mapActions("ProductGroup", {
      setProductGroupList: "setList",
    }),
    ...mapActions("SubCategory", {
      setSubCategory: "setFilteredList",
      setAllSubCategory: "setList",
    }),
    ...mapActions("Category", {
      setCategory: "setFilteredList",
    }),
    ...mapActions("Category", {
      setAllCategory: "setList",
    }),
    ...mapActions("Brand", {
      setBrand: "setList",
    }),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    onload() {
      // toastAlert("success");
      this.setBrand();
      if (!this.adm) {
        this.setProductGroupList();
      }
      this.adm = JSON.parse(localStorage.getItem("user")).admin;

      if (this.$route.params.id) {
        this.setAllCategory();
        this.setAllSubCategory();
        this.show(this.$route.params.id);
      }
      if (this.adm) {
        this.setCompany();
      }
      this.setDefinition();
    },
  },
  mounted() {
    this.onload();
  },
  beforeDestroy: function () {
    this.cleanItem();
    this.cleanErrors();
  },
  destroyed() {},
};
</script>

<style>
.custom-row {
  margin-top: 16px; /* Espaçamento vertical */
}

.custom-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom-label {
  margin-bottom: 8px; /* Espaçamento abaixo do label */
  font-weight: bold; /* Negrito para destaque */
  color: #1976d2; /* Azul primário do Vuetify */
}

.custom-money {
  padding: 8px 12px; /* Espaçamento interno */
  border: 1px solid #ccc; /* Borda sutil */
  border-radius: 8px; /* Borda arredondada */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
  width: 100%; /* Ocupar largura total */
  font-size: 16px; /* Tamanho da fonte consistente */
}

.custom-money:focus {
  border-color: #1976d2; /* Realce ao focar no campo */
  box-shadow: 0 0 4px rgba(25, 118, 210, 0.5); /* Brilho ao redor */
}

/* 
 Container de avisos para descrição 
*/

.custom-container {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-list {
  margin-left: 16px;
  list-style: none;
  padding: 0;
}

.custom-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
}

.custom-warning-box {
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #ffcccc;
  border-radius: 8px;
  background-color: #ffe6e6;
  box-shadow: 0 2px 4px rgba(255, 0, 0, 0.1);
}

.custom-warning-box h4 {
  margin-bottom: 12px;
  font-weight: bold;
}

.text-primary {
  color: #1976d2; /* Azul primário */
}

.text-success {
  color: #28a745; /* Verde de sucesso */
}

.text-danger {
  color: #dc3545; /* Vermelho para alertas */
}

.text-warning {
  color: #ffc107; /* Amarelo para avisos */
}

.mr-2{}

/* 
 Container do campo para descrição 
*/

.custom-textarea {
  font-size: 14px;
  border-radius: 8px;
}

.custom-textarea:hover {
  border-color: #1976d2;
}

.custom-textarea:focus {
  border-color: #1976d2;
  box-shadow: 0 0 6px rgba(25, 118, 210, 0.5);
}

.custom-textarea .v-input__control {
  padding: 10px;
}

.custom-textarea .v-label {
  color: #1976d2; /* Azul primário */
  font-weight: bold;
}


.form-header {
  text-align: center;
  margin: 20px 0;
}

/* 
Título
*/

.title-topo {
  font-family: 'Roboto', sans-serif;
  color: #000000; /* Azul primário */
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-mobile {
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 0;
  border-bottom: 1px solid #000000;
}

.title-desktop {
  font-size: 36px;
  text-transform: uppercase;
  padding: 15px 0;
  border-bottom: 1px solid #000000;
  margin-bottom: 10px;
}

.v-icon {
  color: #1976d2;
}


</style>