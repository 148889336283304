import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'

export const loadChartData = function ({ commit }, { startMonth, startYear, endMonth, endYear }) {
    spinnerChoice('full', true); // Ativa o spinner durante a requisição
    console.log(startMonth, startYear, endMonth, endYear )

    // Fazendo a requisição com os parâmetros de intervalo de data
    axios.post(`${baseApiUrl}/dashboard/home`, { startMonth, startYear, endMonth, endYear })
        .then(res => {
            // Commit dos dados retornados ao Vuex
            commit("SET_CHARTDATA", res.data);
        })
        .catch(e => {
            // Tratamento de erros
            if (e.response && e.response.status === 422) {
                commit('SET_ERRORS', e.response.data.errors);
            } else {
                catchError(e.response?.status || e.message);
            }
        })
        .finally(() => {
            spinnerChoice('full', false); // Desativa o spinner
        });
};

export const loadMonthlyData = async function ({ commit }) {
    try {
      const response = await axios.get(`${baseApiUrl}/sales/monthly`);
      commit("SET_MONTHLY_DATA", response.data);
      return response.data;
    } catch (error) {
      console.error("Erro ao carregar dados mensais:", error);
    }
  };

export const changeDelivery = function ({ commit }, value) {

    spinnerChoice('modal', true)

    axios.post(`${baseApiUrl}/delivery-settings/change-open-delivery`, { open_delivery: value })
        .then(res => {
            commit("SET_OPENDELIVERY", res.data.open_delivery)
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

export const showDeliveryOpen = function ({ commit }) {

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/delivery-settings/show`)
        .then(res => {
            commit("SET_OPENDELIVERY", res.data[0].open_delivery)
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

export default {
    loadChartData,
    showDeliveryOpen,
    changeDelivery
}