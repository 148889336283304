<template>
  <admin-blade-integration
    :iconBtnTop="removeButtonIfNotAdmin(adm)"
    routeBtnTop="/integrations/stock"
  >
    <v-container class="mt-5">
      <v-card class="elevation-3 p-4">
        <v-card-title class="title">
          <v-icon left color="primary">mdi-link</v-icon> Gerar Link Integração
        </v-card-title>

        <v-divider class="mb-4"></v-divider>

        <v-form ref="form" v-model="valid" class="form-container">
          <v-row>
            <v-col sm="6" cols="12" v-if="adm">
              <v-autocomplete
                v-model="formData.company_id"
                :items="companyList"
                item-text="fantasy_name"
                item-value="id"
                label="Selecione a Loja *"
                outlined
                color="primary"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col sm="6" cols="12">
              <v-autocomplete
                v-model="formData.key_name"
                :items="integrators"
                item-text="name"
                item-value="value"
                label="Escolha o Integrador *"
                outlined
                color="primary"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" class="text-right mt-4">
              <v-btn
                v-if="formData.id"
                :disabled="!formData.key_name"
                color="primary"
                dark
                @click="update(formData)"
              >
                <v-icon left>mdi-content-save</v-icon> Salvar Alterações
              </v-btn>

              <v-btn
                v-else
                :disabled="!formData.key_name"
                color="primary"
                dark
                @click="save(formData)"
              >
                <v-icon left>mdi-plus</v-icon> Gerar Link
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-alert
          v-if="errors.message"
          type="error"
          outlined
          class="mt-4"
          border="left"
        >
          {{ errors.message }}
        </v-alert>

        <v-expand-transition>
          <v-card
            v-show="dialog"
            class="elevation-2 mt-4"
            :class="{ 'show-details': dialog }"
          >
            <v-card-title class="subtitle-1"
              >Detalhes da Integração</v-card-title
            >
            <v-card-text>
              <v-row class="mb-2">
                <v-col cols="12">
                  <strong>Link:</strong>
                  <div class="link-container">
                    <span class="link-text">{{ cleanLinkApi }}</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="copy(cleanLinkApi)" v-bind="attrs" v-on="on">
                          <v-icon color="primary">mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copiar Link</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <strong>Token:</strong>
                  <div class="token-container">
                    <span class="token-text">{{ tokenValue }}</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="copy(tokenValue)" v-bind="attrs" v-on="on">
                          <v-icon color="primary">mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copiar Token</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-container>
  </admin-blade-integration>
</template>


<script>
import AdminBladeIntegration from "@/Layouts/BladeIntegrations";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBladeIntegration,
  },

  data() {
    return {
      valid: true, // Controle de validação do formulário

      adm: false, // Verifica se o usuário é admin

      link: "", // Armazena o link gerado
    };
  },

  computed: {
    // Mapeia os estados necessários do Vuex

    ...mapState("Auth", ["user"]),

    ...mapState("StockIntegration", [
      "formData",

      "errors",

      "integrators",

      "linkApi",

      "dialog",
    ]),

    ...mapState("Company", {
      companyList: "list",
    }),

    // Obtém o valor do token a partir da URL

    tokenValue() {
      const tokenIndex = this.linkApi.indexOf("token=");

      return tokenIndex !== -1 ? this.linkApi.substring(tokenIndex + 6) : "";
    },

    // Remove o token da URL para exibir o link limpo

    cleanLinkApi() {
      const tokenIndex = this.linkApi.indexOf("&token=");

      return tokenIndex !== -1
        ? this.linkApi.substring(0, tokenIndex)
        : this.linkApi;
    },
  },

  methods: {
    // Mapeia as ações do Vuex

    ...mapActions("Company", {
      setCompany: "setList",
    }),

    ...mapActions("StockIntegration", ["save", "show", "update"]),

    // Remove o botão superior para usuários não-admin

    removeButtonIfNotAdmin(value) {
      return value ? "fas fa-file-invoice-dollar" : "";
    },

    // Define o modal de exibição de links

    setModal(value) {
      this.$store.commit("StockIntegration/SET_DIALOG", value);
    },

    // Copia texto para a área de transferência

    copy(info) {
      navigator.clipboard.writeText(info).then(() => {
        this.$toast.success("Copiado para a área de transferência!");
      });
    },

    // Define os comportamentos ao montar o componente

    initializePage() {
      const user = JSON.parse(localStorage.getItem("user"));

      this.adm = user?.admin || false;

      if (this.adm) {
        // Admins podem selecionar a empresa

        this.setCompany();
      } else {
        // Define o ID da empresa para usuários não-admin

        this.formData.company_id = user?.company_id || null;

        if (!this.formData.company_id) {
          this.$toast.error(
            "Não foi possível capturar o ID da empresa. Verifique suas permissões."
          );
        }
      }
    },
  },

  mounted() {
    this.initializePage(); // Inicializa a página
  },
};
</script>

<style scoped>
/* Estilo geral do formulário */
.default-form {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

/* Título do card */
.v-card-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
}

.title .v-icon {
  margin-right: 8px; /* Espaçamento entre o ícone e o texto */
}

/* Botões */
.v-btn {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.v-btn:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Campos de entrada */
.v-autocomplete {
  margin-bottom: 20px;
}

.v-autocomplete input {
  font-size: 1rem;
  color: #333;
}

.v-autocomplete .v-input__control {
  border-radius: 5px;
}

/* Mensagens de erro */
.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: right;
  margin-top: -15px;
  margin-bottom: 15px;
}

/* Exibição de detalhes da integração */
.show-details {
  border-left: 4px solid #1976d2; /* Adiciona uma borda azul à esquerda */
  padding-left: 15px; /* Adiciona espaçamento à esquerda */
}

.link-text {
  font-family: monospace; /* Usa uma fonte monoespaçada para o link */
  font-size: 0.9rem;
  word-break: break-all;
  margin-right: 10px; /* Adiciona espaçamento entre o link e o botão */
}

.token-text {
  font-family: monospace; /* Usa uma fonte monoespaçada para o token */
  font-size: 0.7rem;
  word-break: break-all;
  margin-right: 10px; /* Adiciona espaçamento entre o token e o botão */
}

.show-details {
  border-left: 4px solid #1976d2; /* Adiciona uma borda azul à esquerda */
  padding-left: 15px; /* Adiciona espaçamento à esquerda */
}

.link-container,
.token-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adiciona espaçamento entre o link/token e o botão */
}

.link-text {
  font-family: monospace; /* Usa uma fonte monoespaçada para o link */
  font-size: 0.9rem;
  word-break: break-all;
  flex-grow: 1; /* Permite que o texto do link ocupe o espaço disponível */
}

.token-text {
  font-family: monospace; /* Usa uma fonte monoespaçada para o token */
  font-size: 0.9rem;
  word-break: break-all;
  flex-grow: 1; /* Permite que o texto do token ocupe o espaço disponível */
}

/* Responsividade */
@media (max-width: 768px) {
  /* ... (outros estilos responsivos) */

  .link-container,
  .token-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .link-text,
  .token-text {
    margin-bottom: 5px; /* Adiciona espaçamento entre o link/token e o botão em telas menores */
  }
}
/* Responsividade */
@media (max-width: 768px) {
  .v-card-title {
    font-size: 1.5rem;
  }

  .v-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
</style>
