const SET_SHOWINACTIVE = (state, obj) => {
    state.showInactive = obj
}

const SET_ITEMSPERPAGE = (state, obj) => {
    state.pagination.itemsPerPage = obj
}

const SET_CURRENTPAGE = (state, obj) => {
    state.pagination.currentPage = obj
}

const SET_ORDERFIELD = (state, obj) => {
    state.order.field = obj
}

const SET_ORDERVALUE = (state, obj) => {
    state.order.value = obj
}

const SET_PRODUCTNAME = (state, obj) => {
    state.filter.productName = obj
}


const SET_REFERENCE = (state, obj) => {
    state.filter.reference = obj
}

const SET_DEFINITION = (state, obj) => {
    state.filter.definition = obj
}

const SET_CATEGORY = (state, obj) => {
    state.filter.category = obj
}

const SET_SUBCATEGORY = (state, obj) => {
    state.filter.subcategory = obj
}

const SET_CLIENTFILTER = (state, obj) => {
    state.filter.client = obj
}

const SET_SHOWGRID = (state, obj) => {
    state.filter.grid.showGrid = obj
}

const SET_GRIDCODE = (state, obj) => {
    state.filter.grid.code = obj
}

const SET_COLOR = (state, obj) => {
    state.filter.grid.color = obj
}

const SET_SIZE = (state, obj) => {
    state.filter.grid.size = obj
}

const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_LIST = (state, obj) => {
    state.list = obj
}


const SET_LIST_EXACT_SEARCH = (state, obj) => {
    state.listExactProducts = obj;
}

const CLEAR_LIST_EXACT_SEARCH = (state, obj) => {
    state.exactSearchProducts = obj;
    state.listExactProducts = obj;
}
const SET_COMPANY_ID = (state, obj) => {
    state.companyId = obj; // Atualiza o companyId no estado
};

const SET_IMAGES = (state, obj) => {
    state.images = obj; // Atualiza a lista de imagens no estado
};




export default {
    SET_SHOWINACTIVE,
    SET_ITEMSPERPAGE,
    SET_CURRENTPAGE,
    SET_ORDERFIELD,
    SET_ORDERVALUE,
    SET_PRODUCTNAME,
    SET_REFERENCE,
    SET_DEFINITION,
    SET_CATEGORY,
    SET_SUBCATEGORY,
    SET_SHOWGRID,
    SET_GRIDCODE,
    SET_COLOR,
    SET_SIZE,
    SET_FORMDATA,
    SET_ERRORS,
    SET_LIST,
    SET_CLIENTFILTER,
    SET_LIST_EXACT_SEARCH,
    CLEAR_LIST_EXACT_SEARCH,
    SET_COMPANY_ID, // Nova mutation para o companyId
    SET_IMAGES, // Nova mutation para as imagens
}