<template>
  <admin-blade>
    <!-- Título Principal -->
    <h1 class="title-topo">Lista de Compras em Separação</h1>

    <!-- Campo de Busca -->
    <v-row class="mb-4">
      <v-col cols="12" md="6">
        <v-text-field
          class="custom-search"
          v-model="search"
          label="Buscar Compra"
          single-line
          hide-details
          outlined
          clearable
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Tabela de Compras -->
    <v-row>
      <v-col cols="12">
        <v-card class="default-table elevation-2">
          <v-data-table
            :headers="headers"
            :items="list"
            :search="search"
            :footer-props="footer"
            class="rounded-table"
            dense
          >
            <!-- FRETE -->
            <template v-slot:item.delivery_price="{ item }">
              <span class="price">
                R$
                {{
                  parseFloat(item.delivery_price).toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
              </span>
            </template>

            <!-- VALOR DA COMPRA -->
            <template v-slot:item.products_value="{ item }">
              <span class="price">
                R$
                {{
                  parseFloat(item.products_value).toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
              </span>
            </template>

            <!-- TOTAL -->
            <template v-slot:item.total="{ item }">
              <span class="price">
                R$
                {{ sumTotalValue(item.products_value, item.delivery_price) }}
              </span>
            </template>

            <!-- STATUS COM CORES -->
            <template v-slot:item.status="{ item }">
              <v-chip
                :color="getStatusColor(item.status)"
                dark
                class="status-chip"
              >
                {{ changeStatusToName(item.status) }}
              </v-chip>
            </template>

            <!-- TIPO DE PAGAMENTO -->
            <template v-slot:item.payment_type="{ item }">
              <span>{{ setPaymentType(item.payment) }}</span>
            </template>

            <!-- AÇÕES -->
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="edit(item.id)">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Data", value: "created_at", class: "black--text" },
        { text: "Tipo Envio", value: "send_type", class: "black--text" },
        { text: "Tipo Pgto", value: "payment_type", class: "black--text" },
        { text: "Frete", value: "delivery_price", class: "black--text" },
        { text: "Valor da Compra", value: "products_value", class: "black--text" },
        { text: "Total", value: "total", class: "black--text" },
        { text: "Comprador", value: "people_name", class: "black--text" },
        { text: "Status", value: "status", class: "black--text" },
        { text: "Ações", value: "action", sortable: false, class: "black--text" },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Utils", ["paymentStatus"]),
    ...mapState("SortingOutBuys", ["list"]),
  },

  methods: {
    setPaymentType(value) {
      if (value.length > 0) {
        switch (value[0].type) {
          case 0:
            return "C. Crédito";
          case 1:
            return "C. Débito";
          case 2:
            return "Dinheiro";
          case 3:
            return "Boleto";
          case 4:
            return "Pix";
          default:
            return "Outro";
        }
      } else {
        return "Ver Painel";
      }
    },
    sumTotalValue(a, b) {
      return (parseFloat(a) + parseFloat(b)).toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    changeStatusToName(value) {
      const response = this.paymentStatus.find((status) => status.value == value);
      return response ? response.name : "Desconhecido";
    },
    getStatusColor(status) {
      const statusColors = {
        0: "grey",          // Pagamento Pendente
        1: "orange",        // Em Análise
        2: "yellow darken-1",        // Separação p/ Entrega
        3: "blue",          // Em Trânsito
        4: "green",         // Entregue
        5: "red",           // Expirado
        6: "red darken-3",  // Cancelado
        7: "red darken-2",  // Pagamento Recusado
      };
      return statusColors[status] || "grey";
    },
    ...mapActions("SortingOutBuys", ["setList"]),
    edit(id) {
      this.$router.push(`detailOrder/${id}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(1, 0, {
        text: "Cliente",
        value: "fantasy_name",
        class: "black--text",
      });
    }
  },
};
</script>


<style scoped>
.title-topo {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #4caf50;
  padding-bottom: 10px;
}

.custom-search {
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s;
  
}

.custom-search:hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.default-table {
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.price {
  font-weight: bold;
  color: #388e3c;
}

.status-chip {
  font-weight: bold;
  text-transform: uppercase;
  color:  rgb(255, 255, 255);

}

.v-btn {
  margin: 0 4px;
}

@media (max-width: 960px) {
  .v-data-table {
    font-size: 12px;
  }

  .title-topo {
    font-size: 24px;
  }

  .custom-search {
    font-size: 14px;
  }
}
</style>
