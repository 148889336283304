<template>
  <span class="mobile" style="height: 100vh; display: flex; flex-direction: column;">
    <spinner v-show="spinnerFullScreen" />
    <v-row align="center" no-gutters style="height: 100vh;">
      <v-col
      cols="12"
      sm="6"
      class="fill-height"
      style="background:#fff;"
      v-show="!isMobile"
    >
      <div class="divImage">
        <v-img class="imag" src="/img/MarianaLogo.png" style="object-fit: cover;" />
      </div>
    </v-col>    
      
      <v-col cols="12" sm="6" class="container" align="center">
        <div class="teste">
          <v-card-text class="d-flex;">
            <h1 class="title">LOGIN</h1>
            <strong class="red--text">{{ errors.email }}</strong>
            <div class="inputs">
              <p class="userLogin">Usuário</p>
              <v-text-field
                reverse
                outlined
                dense
                color="white"
                prepend-inner-icon="fas fa-user"
                autofocus
                placeholder="Email"
                name="Email"
                type="text"
                class="inputText"
                v-model="formData.email"
                @keyup.enter="login(formData)"
              ></v-text-field>
            </div>
            <strong class="red--text">{{ errors.password }}</strong>
            <div class="inputs">
              <p class="userLogin">Senha</p>
              <v-text-field
                reverse
                outlined
                dense
                class="inputText"
                color="white"
                prepend-inner-icon="fas fa-lock"
                id="password"
                placeholder="Senha"
                name="Senha"
                type="password"
                v-model="formData.password"
                @keyup.enter="login(formData)"
              ></v-text-field>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn class="btn" color="white" @click="login(formData)">Login</v-btn>
          </v-card-actions>
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import Spinner from "@//Layouts/Components/spinner";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Spinner,
  },
  data: function () {
    return {
      formData: {
        email: null,
        senha: null,
      },
      isMobile: window.innerWidth < 740, 
    };
  },
  computed: {
    ...mapState("Spinner", ["spinnerFullScreen"]),
    ...mapState("Auth", ["errors", "cleanErrors"]),
  },
  methods: {
    ...mapActions("Auth", ["login"]),
    handleResize() {
      this.isMobile = window.innerWidth < 740;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  destroyed() {
    this.cleanErrors();
  },
};
</script>



<style scoped>
@media only screen and (max-width: 1000px) {
  .inputText {
    width: 300px !important;
    margin-left: -20px;
    
  }
  .mobile{
    background: #002b43;
  }
  .teste {
    width: 90% !important;
    height: 400px !important;
    margin: 0; /* Remove a margem */
    border-radius: 50px;
  }
  .userLogin {
    text-align: start !important;
    color: black;
  }
  .title{
    background: white;
    margin: 30px !important;
  }
}
.teste {
  background: #fff;
  width: 70%;
  height: 58%;
  margin: 0; /* Remove a margem */
  border-radius: 50px;
}

.container {
  background: #002b43;
  width: 100%; /* Ajuste para ocupar 100% */
  height: 100vh; /* Ocupar a altura total da tela */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}

.divImage {
  background: #e66b25;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.inputs {
  width: 340px;
}

.inputText {
  width: 100%;
}

.userLogin {
  text-align: left;
  color: black;
}

.title {
  text-align: center;
  font-family: Arial, Helvetica;
  margin: 15% 0 5% 0;
  color: black;
}

.btn {
  color: white;
  background: rgb(81, 62, 255) !important;
  margin: 0 auto 10% auto;
  width: 60%;
  border-radius: 5px;
}

.imag {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Garante que a imagem preencha o espaço */
}

.card-class {
  background: #fff;
  border-radius: 0px !important;
  margin-top: 0;
  height: 100vh;
}
</style>
