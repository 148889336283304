<template>
  <span>
    <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-company">
      <h1 class="title-topo">Lista de Clientes</h1>

      <v-card class="filter-bar elevation-2 mb-4">
        <v-container fluid>
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="4">
              <v-switch
                color="amber darken-4"
                @change="setList()"
                v-model="showInactive"
                label="Mostrar Inativos"
                class="switch-label"
                hide-details
              >
                <template v-slot:prepend>
                  <v-icon color="grey darken-1">mdi-account-off</v-icon>
                </template>
              </v-switch>
            </v-col>

            <v-col cols="12" md="4">
              <v-switch
                color="amber darken-4"
                @change="setList()"
                v-model="only_delivered"
                label="Somente Entregues"
                class="switch-label"
                hide-details
              >
                <template v-slot:prepend>
                  <v-icon color="grey darken-1">mdi-truck-delivery</v-icon>
                </template>
              </v-switch>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                class="default-search"
                v-model="search"
                label="Buscar por Nome ou E-mail"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-data-table
        class="default-table elevation-1"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.fantasy_name="{ item }">
          {{ item.fantasy_name }}
        </template>

        <template v-slot:item.active="{ item }">
          <v-chip
            :color="item.active ? 'green dark' : 'red darken'"
            :text-color="item.active ? 'white' : 'white'"
            small
            class="status-chip"
          >
            {{ item.active ? 'Ativo' : 'Inativo' }}
          </v-chip>
        </template>

        <template v-slot:item.contract_close="{ item }">
          <span v-if="item.contract_close">
            {{ toConvertData(item.contract_close) }}
          </span>
        </template>

        <template v-slot:item.project_delivery="{ item }">
          <span v-if="item.project_delivery">
            {{ toConvertData(item.project_delivery) }}
          </span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2 action-icon"
            @click="editItem(item.id)"
            title="Editar"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </admin-blade>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AdminBlade from "@/Layouts/Blade";
import { toastAlert } from "@/utils/Alerts/toast";
import { dateFormatDMA } from "@/utils/dateFunctions";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      panel: 1,
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "CLIENTE", value: "fantasy_name", class: "black--text" },
        { text: "STATUS", value: "active", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        {
          text: "Data Fechamento",
          value: "contract_close",
          class: "black--text",
        },
        {
          text: "Data Entrega",
          value: "project_delivery",
          class: "black--text",
        },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Company", ["list"]),

    showInactive: {
      get() {
        return this.$store.state.Company.showInactive;
      },
      set(value) {
        this.$store.commit("Company/SET_SHOWINACTIVE", value);
      },
    },

    only_delivered: {
      get() {
        return this.$store.state.Company.only_delivered;
      },
      set(value) {
        this.$store.commit("Company/SET_ONLY_DELIVERED", value);
      },
    },
  },

  methods: {
    ...mapActions("Company", ["setList"]),

    // Verificação de permissão
    checkUserFullPermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user && user.full_permission === true;
    },

    // Método de edição com verificação
    editItem(item) {
      if (!this.checkUserFullPermission()) {
        alert("Você não tem permissão para alterar este item.");
        return;
      }
      this.$router.push(`form-company/${item}`);
    },

    toConvertData(data) {
      return dateFormatDMA(data);
    },
  },

  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>


<style scoped>
/* Estilos gerais */
.container {
  padding: 20px; /* Espaçamento interno do container */
  font-family: 'Roboto', sans-serif; /* Fonte moderna */
}

/* Estilos para o título */
.title-topo {
  font-size: 1.8rem;
  font-weight: 700;
  color: #333; /* Cor de texto escura */
  margin-bottom: 1.5rem;
}

/* Estilos para a barra de filtros */
.filter-bar {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  padding: 1rem;
}

.switch-label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #444;
}

.default-search {
  margin-bottom: 0;
}

/* Estilos para a tabela */
.default-table {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.v-data-table-header {
  background-color: #f5f5f5; /* Cor de fundo suave para o cabeçalho */
}

.v-data-table-header .black--text {
  font-weight: 600;
  color: #333;
}

.v-data-table tbody tr:hover {
  background-color: #f9f9f9; /* Cor de fundo suave para linhas em hover */
}

/* Estilos para os chips de status */
.status-chip {
  font-weight: 500;
}

.status-chip.green { /* Classe para chips verdes */
  background-color: #e0f2f7; /* Verde mais suave */
  color: #00838f; /* Verde mais escuro */
}

.status-chip.red { /* Classe para chips vermelhos */
  background-color: #ffebee; /* Vermelho mais suave */
  color: #c62828; /* Vermelho mais escuro */
}

/* Estilos para os ícones de ação */
.action-icon {
  cursor: pointer;
  color: #2196f3;
  transition: color 0.2s ease; /* Adiciona uma transição suave */
}

.action-icon:hover {
  color: #1976d2;
}
</style>