export default {
  genders: [
    { name: "Masculino", value: "m" },
    { name: "Feminino", value: "f" },
    { name: "Unisex", value: "u" }
  ],
  colors: [

    { name: "Amarelo" },
    { name: "Azul" },
    { name: "Azul BIC" },
    { name: "Azul celeste" },
    { name: "Azul marinho" },
    { name: "Babaloo" },
    { name: "Bege" },
    { name: "Bordô" },
    { name: "Branco" },
    { name: "Bronze" },
    { name: "Caramelo" },
    { name: "Castanho" },
    { name: "Caqui" },
    { name: "Champage" },
    { name: "Chocolate" },
    { name: "Cobre" },
    { name: "Cinza" },
    { name: "Creme" },
    { name: "Dourado" },
    { name: "Ferrugem" },
    { name: "Fucsia" },
    { name: "Gelo" },
    { name: "Goiaba" },
    { name: "Jeans" },
    { name: "Jeans Claro" },
    { name: "Jeans Médio" },
    { name: "Jeans Escuro" },
    { name: "Laranja" },
    { name: "Lavanda" },
    { name: "Lilás" },
    { name: "Lima" },
    { name: "Marfim" },
    { name: "Marrom" },
    { name: "Menta" },
    { name: "Mostarda" },
    { name: "Marsala" },
    { name: "Nude" },
    { name: "Ouro" },
    { name: "Orgave" },
    { name: "Pêssego" },
    { name: "Petróleo" },
    { name: "Prata" },
    { name: "Preto" },
    { name: "Púrpura" },
    { name: "Rosa" },
    { name: "Rose" },
    { name: "Roxo" },
    { name: "Rubro" },
    { name: "Salmão" },
    { name: "Sépia" },
    { name: "Terracota" },
    { name: "Tijolo" },
    { name: "Turquesa" },
    { name: "Uva" },
    { name: "Verde" },
    { name: "Vermelho" },
    { name: "Vinho" },
    { name: "Violeta" },
    { name: "Pink" },
    { name: "Chumbo" },
    { name: "Off White" },
    { name: "N/A" },

  ],
  sizes: [
    { name: 'pp' },
    { name: 'p' },
    { name: 'm' },
    { name: 'g' },
    { name: 'gg' },
    { name: 'xg' },
    { name: 'xxg' },
    { name: 'exg' },
    { name: 'g1' },
    { name: 'g2' },
    { name: 'g3' },
    { name: 'g4' },
    { name: 'g5' },
    { name: 'g6' },
    { name: 'eg' },
    { name: 'egg' },
    { name: 'U' },
    { name: '1' },
    { name: '2' },
    { name: '3' },
    { name: '4' },
    { name: '5' },
    { name: '6' },
    { name: '7' },
    { name: '8' },
    { name: '9' },
    { name: '10' },
    { name: '11' },
    { name: '12' },
    { name: '13' },
    { name: '14' },
    { name: '15' },
    { name: '16' },
    { name: '17' },
    { name: '18' },
    { name: '19' },
    { name: '20' },
    { name: '21' },
    { name: '22' },
    { name: '23' },
    { name: '24' },
    { name: '25' },
    { name: '26' },
    { name: '27' },
    { name: '28' },
    { name: '29' },
    { name: '30' },
    { name: '31' },
    { name: '32' },
    { name: '33' },
    { name: '34' },
    { name: '35' },
    { name: '36' },
    { name: '37' },
    { name: '38' },
    { name: '39' },
    { name: '40' },
    { name: '41' },
    { name: '42' },
    { name: '43' },
    { name: '44' },
    { name: '45' },
    { name: '46' },
    { name: '47' },
    { name: '48' },
    { name: '49' },
    { name: '50' },
    { name: '51' },
    { name: '52' },
    { name: '53' },
    { name: '54' },
    { name: '55' },
    { name: '56' },
    { name: '57' },
    { name: '58' },
    { name: '59' },
    { name: '60' },
    { name: '61' },
    { name: '62' },
    { name: 'XG1' },
    { name: 'XG2' },
    { name: 'XG3' },
    { name: 'p/m' },
    { name: 'g/gg' },
    { name: '40A' },
    { name: '40B' },
    { name: '40C' },
    { name: '40D' },
    { name: '40DD' },
    { name: '42A' },
    { name: '42B' },
    { name: '42C' },
    { name: '42D' },
    { name: '42DD' },
    { name: '44A' },
    { name: '44B' },
    { name: '44C' },
    { name: '44D' },
    { name: '44DD' },
    { name: '46A' },
    { name: '46B' },
    { name: '46C' },
    { name: '46D' },
    { name: '46DD' },
    { name: '48B' },
    { name: '48C' },
    { name: '48D' },
    { name: '48DD' },
    { name: '50A' },
    { name: '50B' },
    { name: '50C' },
    { name: '50D' },
    { name: '15/16' },
    { name: '17/18' },
    { name: '19/20' },
    { name: '20/21' },
    { name: '21/22' },
    { name: '23/24' },
    { name: '25/26' },
    { name: '26/27' },
    { name: '27/28' },
    { name: '28/29' },
    { name: '29/30' },
    { name: '30/31' },
    { name: '31/32' },
    { name: '32/33' },
    { name: '33/34' },
    { name: '34/35' },
    { name: '35/36' },
    { name: '36/37' },
    { name: '37/38' },
    { name: '39/40' },
    { name: '41/42' },
    { name: '43/44' },
    { name: '65' },
    { name: '75' },

    { name: 'Prematuro' },
    { name: 'RN' },
    { name: '3m' },
    { name: '6m' },
    { name: '9m' },
    { name: '12m' },
    { name: '18m' },
    { name: '24m' },
    { name: '2T' },
    { name: '3T' },
    { name: '4T' },
    { name: '5T' },
    { name: '6T' },
    { name: '6X' },
    { name: '70' },
    { name: '80' },
    { name: '81' },
    { name: '82' },
    { name: '83' },
    { name: '84' },
    { name: '85' },
    { name: '86' },
    { name: '87' },
    { name: '88' },
    { name: '89' },
    { name: '90' },
    { name: '91' },
    { name: '92' },
    { name: '93' },
    { name: '94' },
    { name: '95' },
    { name: '96' },
    { name: '97' },
    { name: '98' },
    { name: '99' },
    { name: '100' },
    { name: '101' },
    { name: '102' },
    { name: '103' },
    { name: '104' },
    { name: '105' },
    { name: '106' },
    { name: '107' },
    { name: '108' },
    { name: '109' },
    { name: '110' },
    { name: '111' },
    { name: '112' },
    { name: '113' },
    { name: '114' },
    { name: '115' },
    { name: '116' },
    { name: '117' },
    { name: '118' },
    { name: '119' },
    { name: '120' },
    { name: '121' },
    { name: '122' },
    { name: '123' },
    { name: '124' },
    { name: '125' },
    { name: '126' },
    { name: '127' },
    { name: '128' },
    { name: '129' },
    { name: '130' },
    { name: '131' },
    { name: '132' },
    { name: '133' },
    { name: '134' },
    { name: '135' },

    { name: '30 ml' },
    { name: '50 ml' },
    { name: '75 ml' },
    { name: '100 ml' },
    { name: '125 ml' },
    { name: '200 ml' },

    { name: '10' },
    { name: '0-2m' },
    { name: '0-6m' },
    { name: '6-18m' },
    { name: '18m+' },
    { name: '3m+' },
    { name: '0-3m' },

    { name: '57x15x1V' },
    { name: '57x30x1V' },
    { name: '57x150' },
    { name: '76x30x1V' },
    { name: '89x40x1' },


    { name: "N/A" },

    { name: '1 - Infantil 70x130cm' },
    { name: '2 - Solteiro 78x188cm' },
    { name: '3 - Solteiro 88x188cm' },
    { name: '4 - Solteirão 100x200cm' },
    { name: '5 - Viúvo 120x203cm' },
    { name: '6 - Casal 128x188cm' },
    { name: '7 - Casal 138x188cm' },
    { name: '8 - Queen 158x198cm' },
    { name: '9 - King 193x203cm' },
    { name: 'Sob Medida' },

    { name: '1 - Solteiro' },
    { name: '2 - Solteirão' },
    { name: '3 - Casal' },
    { name: '4 - Queen Size' },
    { name: '5 - King Size' },

  ],

  paymentStatus: [
    { value: 0, name: 'Pagamento Pendente' },
    { value: 1, name: 'Em Analise' },
    { value: 2, name: 'Separação p/ Entrega' },
    { value: 3, name: 'Em transito' },
    { value: 4, name: 'Entregue' },
    { value: 5, name: 'Expirado' },
    { value: 6, name: 'Cancelado' },
    { value: 7, name: 'Pagamento Recusado' }

  ]

}