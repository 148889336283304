<template>
  <span>
    <admin-blade>
      <div v-if="checkIfAdminOrders">
        <dashboard />
      </div>
      <div v-else>
        <div class="welcome-page">
          <div class="welcome-content">
            <img
              src="/img/logoCortado.png"
              alt="Gransys Logo"
              class="welcome-logo"
            />
            <h1 class="welcome-title">Bem-vindo ao sistema da Gransys</h1>
            <p class="welcome-message">
              Seu ambiente seguro e eficiente para gerenciar informações e
              operações com facilidade. Pronto para começar?
            </p>
            <button class="get-started-button" @click="redirectToWhatsApp">
              WhatsApp Suporte
            </button>
          </div>
          <div class="welcome-animation">
            <lottie-player
              src="https://assets6.lottiefiles.com/packages/lf20_ktwnwvcl.json"
              background="transparent"
              speed="1"
              style="width: 100%; max-width: 250px; height: auto"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
      </div>
    </admin-blade>
  </span>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import Dashboard from "@/views/Main/Dashboard/dashboard";

export default {
  components: {
    AdminBlade,
    Dashboard,
  },
  data() {
    return {
      checkIfAdminOrders: false, // Inicializa com false
    };
  },
  methods: {
    checkUserFullPermission() {
      let user = JSON.parse(localStorage.getItem("user")) || {};
      this.checkIfAdminOrders = user.full_permission === true;
    },
    redirectToWhatsApp() {
      const whatsappNumber = "5519971695316"; // Substitua pelo número do WhatsApp desejado
      const message = encodeURIComponent(
        "Olá, gostaria de solicitar acesso ao sistema da Gransys."
      );
      window.open(`https://wa.me/${whatsappNumber}?text=${message}`, "_blank");
    },
  },
  mounted() {
    this.checkUserFullPermission();
  },
};
</script>

<style>
.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  background: linear-gradient(135deg, #eef2f3, #8e9eab);
  font-family: "Arial", sans-serif;
  text-align: center;
}

.welcome-content {
  max-width: 90%; /* Ocupa até 90% da largura da tela */
  margin-bottom: 20px;
}

.welcome-logo {
  width: 100%;
  max-width: 200px; /* Limita o tamanho em telas maiores */
  margin-bottom: 15px;
  animation: fadeIn 1s ease-in-out;
}

.welcome-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
  animation: slideInLeft 1s ease-in-out;
}

.welcome-message {
  font-size: 1rem;
  color: #34495e;
  line-height: 1.5;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-in-out;
}

.get-started-button {
  background: #25d366; /* Cor do botão estilo WhatsApp */
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  animation: bounceIn 1.5s ease-in-out;
}

.get-started-button:hover {
  background: #20b858;
}

.welcome-animation {
  width: 100%;
  max-width: 250px; /* Limita o tamanho em telas maiores */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes bounceIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

/* Media Queries para Responsividade */
@media (max-width: 768px) {
  .welcome-title {
    font-size: 1.8rem;
  }

  .welcome-message {
    font-size: 0.9rem;
  }

  .get-started-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .welcome-title {
    font-size: 1.5rem;
  }

  .welcome-message {
    font-size: 0.85rem;
  }

  .welcome-logo {
    max-width: 120px;
  }

  .welcome-animation {
    max-width: 200px;
  }
}
</style>
