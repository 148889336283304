<template>
  <admin-blade>
    <v-row>
      <v-col cols="12">
        <h2 class="form-title">
          Simulação de Landing Page - Gerenciar Imagens
        </h2>
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col cols="12" sm="8">
        <v-text-field
          v-model="imageUrl"
          label="Insira o URL da Imagem"
          placeholder="https://example.com/image.jpg"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn color="blue darken-2" @click="addImage" :disabled="!imageUrl">
          Adicionar Imagem
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="image-uploader elevation-10">
      <div
        class="upload-area"
        @drop.prevent="onDrop"
        @dragover.prevent
        @dragenter="dragging = true"
        @dragleave="dragging = false"
        :class="{ highlight: dragging }"
      >
        <v-row v-if="images.length === 0" class="placeholder-row">
          <v-col cols="12" class="placeholder">
            <i class="fas fa-cloud-upload-alt upload-icon"></i>
            <p class="instructions">
              Adicione URLs ou arraste e solte imagens aqui.
            </p>
          </v-col>
        </v-row>

        <v-row v-else class="landing-preview">
          <v-col
            v-for="(image, index) in images"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="landing-image-card">
              <img :src="image.link" alt="Preview" />
              <div class="image-overlay">
                <p class="image-description">
                  {{ image.name || "Produto Exemplo" }}
                </p>
                <v-btn @click="removeImage(image.id)" icon color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-row justify="center" class="" v-if="showActionButtons">
      <v-col md="6" cols="12"></v-col>
      <v-col md="8" cols="12">
        <v-btn
          @click="fecharUploader"
          class="action-btn"
          color="red darken-2"
          outlined
        >
          Fechar
        </v-btn>
      </v-col>
    </v-row>
  </admin-blade>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data() {
    return {
      imageUrl: "",
      showActionButtons: true,
      dragging: false, // Para o efeito de arrastar e soltar
    };
  },
  computed: {
    ...mapState(["images"]), // Mapeia o estado Vuex para acessar as imagens
  },
  methods: {
    ...mapActions(["fetchCompanyId", "fetchImages"]), // Mapeia as ações Vuex

    async loadCompanyData() {
      try {
        await this.fetchCompanyId(); // Obtém o Company ID
        await this.fetchImages(); // Carrega as imagens
      } catch (error) {
        console.error("Erro ao carregar dados da empresa:", error.message);
      }
    },

    async addImage() {
      if (!this.isValidUrl(this.imageUrl)) {
        alert("URL inválida. Insira um URL válido.");
        return;
      }

      const newImage = {
        name: "Nova Imagem",
        link: this.imageUrl,
        company_id: this.$store.state.companyId,
      };

      try {
        await this.$store.dispatch("addImage", newImage); // Chama uma ação Vuex para adicionar a imagem
        this.imageUrl = ""; // Limpa o campo de URL
        alert("Imagem adicionada com sucesso!");
      } catch (error) {
        console.error("Erro ao adicionar imagem:", error.message);
        alert("Erro ao adicionar a imagem.");
      }
    },

    fecharUploader() {
      this.imageUrl = "";
      this.showActionButtons = false;
    },

    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    },

    onDrop(event) {
      this.dragging = false;
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.$store.commit("SET_IMAGES", [
              ...this.images,
              { link: e.target.result, name: file.name },
            ]);
          };
          reader.readAsDataURL(file);
        }
      }
    },
  },
  mounted() {
    this.loadCompanyData();
  },
};
</script>

<style scoped>
.landing-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  margin-top: 2em;
  justify-content: center;
}

.landing-image-card {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.landing-image-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.landing-image-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-description {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.placeholder-row {
  text-align: center;
}

.placeholder {
  color: #999;
}

.upload-area {
  margin: 0 auto;
  border: 2px dashed #ddd;
  padding: 1em;
  text-align: center;
  border-radius: 8px;
}

.form-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5em;
}
</style>
