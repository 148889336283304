<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-definition">
    <!-- Título -->
    <v-row class="header-row">
      <v-col cols="12">
        <h1 class="title-topo">Definições de Segmentos</h1>
      </v-col>
    </v-row>

    <!-- Campo de Busca -->
    <v-card class="search-card">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            class="default-search"
            v-model="search"
            label="Buscar segmento"
            outlined
            clearable
          >
            <template v-slot:prepend-inner>
              <v-icon color="grey darken-2">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center justify-end">
          <v-btn class="add-segment-btn" color="primary" 
          @click="addSegment">
            <v-icon left>mdi-plus</v-icon> Novo Segmento
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- Tabela de Segmentos -->
    <v-card class="definition-card">
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
        dense
      >
        <template v-slot:item.action="{ item }">
          <v-btn icon small @click="edit(item.id)" title="Editar">
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";


export default {
  props: {
    iconBtnTop: String,
    routeBtnTop: String,
  },
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Nome do Segmento", value: "name", class: "black--text" },
        {
          text: "Ações",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  computed: {
    ...mapState("Definition", ["list"]),
  },
  methods: {
    ...mapActions("Definition", ["setList"]),
    edit(item) {
      this.$router.push(`form-definition/${item}`);
    },
    addSegment() {
      this.$router.push("/form-definition");
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>

<style scoped>
/* Cabeçalho */
.header-row {
  margin-bottom: 20px;
}
.title-topo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 8px;
}

/* Card de Busca */
.search-card {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
}

.default-search {
  border-radius: 8px;
  background-color: #ffffff;
}

.default-search >>> .v-icon {
  font-size: 22px;
}

/* Botão de Novo Segmento */
.add-segment-btn {
  text-transform: uppercase;
  font-weight: bold;
}

/* Card de Definições */
.definition-card {
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Tabela */
.default-table {
  border-radius: 8px;
}

.v-data-table th {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #f2f2f2;
}

.v-data-table tr:nth-child(even) {
  background-color: #fafafa;
}
</style>
