<template>
  <v-card>
    <v-simple-table class="default-table">
      <template v-slot:default>
        <!-- Cabeçalho da Tabela -->
        <thead>
          <tr>
            <th colspan="2">
              <v-select
                :items="[25, 50, 100]"
                v-model="pagination.itemsPerPage"
                style="max-width: 150px"
                @input="setList"
              >
                <template v-slot:prepend>
                  <small>Ítens por Página:</small>
                </template>
              </v-select>
            </th>
            <th v-if="checkUser()" colspan="6" />
            <th v-else colspan="5" />
            <th colspan="2">Total de Itens: {{ list.total }}</th>
          </tr>

          <tr>
            <th v-for="header in headers" :key="header.value" class="text-left">
              <v-btn
                text
                small
                min-width="0"
                class="px-0"
                @click="handleHeaderClick(header)"
              >
                {{ header.name }}
                <v-icon small>{{ header.sortIcon }}</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>

        <!-- Corpo da Tabela -->
        <tbody v-if="list.data && list.data.length > 0">
          <tr class="row-class" v-for="(item, index) in list.data" :key="index">
            <td @click="edit(item)">{{ item.id }}</td>
            <td @click="edit(item)">{{ item.name }}</td>
            <td @click="edit(item)">{{ item.ref }}</td>
            <td @click="edit(item)">{{ item.category }}</td>
            <td v-if="checkUser()" @click="edit(item)">{{ item.company_name }}</td>
            <td @click="edit(item)">
              R$ {{ item.price.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
            </td>
            <td>
              <v-switch
                v-model="item.active"
                color="amber darken-4"
                label=""
                hide-details
                @change="updateActive(item)"
              ></v-switch>
            </td>
            <td>
              <v-chip :class="negativeStock(item.balance)">{{ item.balance }}</v-chip>
            </td>
            <td>{{ item.relevance }}</td>
          </tr>

          <!-- Paginação -->
          <tr>
            <td colspan="10">
              <v-pagination
                v-model="page"
                :length="list.last_page"
                total-visible="10"
                @input="setList"
              ></v-pagination>
            </td>
          </tr>
        </tbody>

        <!-- Mensagem de Nenhum Produto Encontrado -->
        <tbody v-else>
          <tr>
            <td colspan="10">
              <v-alert type="error">Nenhum produto encontrado!</v-alert>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";

export default {
  data: function () {
    return {
      currentHeader: "",
      headers: [
        {
          name: "ID",
          value: "id",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Nome",
          value: "name",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Ref",
          value: "ref",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Categoria",
          value: "category",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Valor",
          value: "price",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Ativo",
          value: "active",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Estoque",
          value: "stock",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Relevancia",
          value: "relevance",

          sortOrder: "",
          sortIcon: "",
        },
      ],
    };
  },
  props: {
    list: {},
  },
  
  computed: {
    ...mapState("Product", ["pagination"]),
    itemsPerPage: {
      get() {
        return this.$store.state.Product.pagination.itemsPerPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    orderField: {
      get() {
        return this.$store.state.Product.order.field;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERFIELD", value);
      },
    },
    orderValue: {
      get() {
        return this.$store.state.Product.order.value;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERVALUE", value);
      },
    },

    // list: function () {
    //   return this.$store.state.Product.list;
    // },
  },

  

  methods: {
    teste(item){
      console.log(item)
    },

    ...mapActions("Product", ["setList", "ExportToCsv", "update", "formData", ]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    updateContent() {
      if (this.formData.relevance == null) {
        this.formData.relevance = 0;
      }
      this.update(this.formData);
    },
    updateItemsPerPage(value) {
      this.pagination.itemsPerPage = value;
      this.setList();
    },
    clearIcon(header) {
      this.currentHeader = header.value;
      for (let i = 0; i < this.headers.length; i++) {
        if (this.headers[i].value != this.currentHeader) {
          this.headers[i].sortOrder = "";
          this.headers[i].sortIcon = "";
        }
      }
    },
    toggleOrder(header) {
      if (header.sortOrder == "" && header.sortIcon == "") {
        header.sortOrder = "ASC";
        header.sortIcon = "mdi-arrow-up";
      } else if (
        header.sortOrder == "ASC" &&
        header.sortIcon == "mdi-arrow-up"
      ) {
        header.sortOrder = "DESC";
        header.sortIcon = "mdi-arrow-down";
      } else if (
        header.sortOrder == "DESC" &&
        header.sortIcon == "mdi-arrow-down"
      ) {
        header.sortOrder = "";
        header.sortIcon = "";
      } else {
        header.sortOrder = "ASC";
        header.sortIcon = "mdi-arrow-up";
      }
    },
    sort(header) {
      if (header.sortOrder) {
        this.orderField = header.value;
        this.orderValue = header.sortOrder;
      } else {
        this.orderField = "id";
        this.orderValue = "DESC";
      }
    },
    getPromotionClass(value) {
      if (value) {
        return "inactive-item-price";
      }
    },
    negativeStock(value) {
      if (value < 1) {
        return "negative-stock-class";
      } else {
        return "stock-class";
      }
    },
    edit(item) {
      this.$router.push(`form-product/${item.id}`);
    },
    checkActiveClass(value) {
      if (value == true) {
        return ["Sim", "active-product-list-true"];
      } else {
        return ["Não", "active-product-list-false"];
      }
    },
    updateActive(item) {
    const payload = {
      id: item.id,
      active: item.active,
      name: item.name
    };
    this.update(payload)
      .then(() => {
        toastAlert("success", "Status do produto atualizado com sucesso!");
      })
      .catch(() => {
        toastAlert("error", "Erro ao atualizar o status do produto.");
      });
  },
  },
  mounted() {
    this.setList();
    toastAlert("success");

    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(4, 0, {
        name: "Cliente",
        value: "company_name",

        sortOrder: "",
        sortIcon: "",
      });
    }
  },
};
</script>

<style scoped>
/* Estilo para linhas ao passar o mouse */
.row-class:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

/* Estilo para itens inativos */
.inactive-item-price {
  text-decoration: line-through;
  color: rgba(128, 128, 128, 0.57);
}

/* Estilos para status ativo/inativo */
.active-product-list-true {
  background: green;
  color: white;
  padding: 5px;
  border-radius: 10px;
}

.active-product-list-false {
  background: red;
  color: white;
  padding: 5px;
  border-radius: 10px;
}

/* Estilos para estoque */
.stock-class {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.negative-stock-class {
  background: red;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
}
</style>