<template>
  <admin-blade iconBtnTop="fas fa-cart-plus" routeBtnTop="/coupon">
    <span>
      <v-form class="mt-5" ref="form" id="formCoupon" v-model="valid">
        <div class="default-form" id="formCoupon">
          <h3 class="title-topo d-block d-sm-none">Formulário Cupom</h3>
          <h1 class="title-topo d-none d-sm-block">Formulário Cupom</h1>
          <br />
          <v-row>
            <v-col cols="12" sm="6" xl="5"
              ><v-text-field
                v-model="formData.code"
                label="Código"
                :rules="rulesFunction(' Código', 1)"
              ></v-text-field
            ></v-col>
            <v-col cols="1" class="d-none d-xl-block" />
            <v-col cols="12" sm="6" xl="5">
              <v-row no-gutters>
                <v-col cols="6" sm="4" md="3" xl="2">
                  <v-select
                    :items="['Taxa', 'Valor']"
                    :value="discountType"
                    v-model="discountType"
                  >
                  </v-select>
                </v-col>
                <v-col cols="1" class="d-none d-sm-block" />
                <v-col cols="6" sm="5" md="3">
                  <v-text-field
                    label="Desconto"
                    type="number"
                    v-if="discountType == 'Valor'"
                    v-model="formData.discount_value"
                    :rules="rulesFunction(' Valor', 0)"
                  >
                    <template v-slot:prepend> R$ </template>
                  </v-text-field>
                  <v-text-field
                    label="Desconto"
                    type="number"
                    v-if="discountType == 'Taxa'"
                    v-model="formData.discount_rate"
                    :rules="rulesFunction(' Taxa', 1)"
                  >
                    <template v-slot:prepend> <span>&nbsp;</span> </template>
                    <template v-slot:append-outer v-if="discountType == 'Taxa'">
                      %
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" xl="5">
              <!-- <v-row no-gutters>
                <v-col cols="12">
                  <v-switch label="Não expira" v-model="doesNotExpire" />
                </v-col>
                <v-col cols="12" xl="6" v-if="!doesNotExpire">
                  <v-datetime-picker
                    :time-picker-props="{
                      useSeconds: false,
                      ampmInTitle: false,
                      format: '24hr',
                    }"
                    :textFieldProps="{ 'prepend-icon': 'mdi-calendar-month' }"
                    timeFormat="HH:mm:ss"
                    v-model="initDateTime"
                    label="Data Início"
                    :rules="rulesFunction(' Data Início', 10)"
                  >
                    <template v-slot:dateIcon>
                      <v-icon>mdi-calendar-month</v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon>mdi-clock-outline</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
                <v-col cols="12" xl="6" v-if="!doesNotExpire">
                  <v-datetime-picker
                    :time-picker-props="{
                      useSeconds: false,
                      ampmInTitle: false,
                      format: '24hr',
                    }"
                    :textFieldProps="{ 'prepend-icon': 'mdi-calendar-month' }"
                    timeFormat="HH:mm:ss"
                    v-model="expireDateTime"
                    label="Data Fim"
                    :rules="rulesFunction(' Data Fim', 10)"
                  >
                    <template v-slot:dateIcon>
                      <v-icon>mdi-calendar-month</v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon>mdi-clock-outline</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
              </v-row> -->
            </v-col>
            <!-- <v-col cols="1" class="d-none d-xl-block" /> -->
            <v-col cols="12" sm="12">
              <v-row no-gutters>
                <v-col cols="12" xl="2">
                  <v-switch v-model="formData.active" label="Ativo"></v-switch>
                </v-col>
                <v-col cols="12" xl="8" v-if="!checkAtributes">
                  <v-switch
                    v-model="formData.remove_sale"
                    label="Não aplicar em promoções"
                  ></v-switch>
                </v-col>
                <v-col cols="12" xl="8" v-else>
                  <p class="text-red" style="color: red">
                    *Se quiser remover promoção, não selecione as Regras de
                    Atributos*
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="adm">
                <v-col cols="6">
                  <v-autocomplete
                    height="36"
                    v-model="formData.company_id"
                    :items="companyList"
                    :disabled="formData.company_id == true"
                    item-text="fantasy_name"
                    item-value="id"
                    label="Loja *"
                    @change="setList(formData.company_id)"
                    :rules="rulesFunction('Loja', 0)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <hr />
              <h3>Regras de Atributos</h3>
              <v-row>
                <v-col sm="6" md="3" cols="12">
                  <v-autocomplete
                    @change="
                      setDefinition(formData.definition_id), setAtributes(true)
                    "
                    v-model="formData.definition_id"
                    :items="definitionList"
                    item-text="name"
                    item-value="id"
                    label="Segmento *"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="6" md="3" cols="12">
                  <v-autocomplete
                    @change="
                      setCategory(formData.category_id), setAtributes(true)
                    "
                    v-model="formData.category_id"
                    :items="categoryList"
                    item-text="name"
                    item-value="id"
                    label="Categoria *"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="6" md="3" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="formData.product_group_id"
                        :items="productGroupList"
                        @change="setAtributes(true)"
                        item-text="name"
                        item-value="id"
                        label="Grupo de Produto *"
                        color="amber darken-4"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="formData.product_group_rule"
                        :items="RuleToProductsAtributes"
                        @change="setAtributes(true)"
                        item-text="name"
                        item-value="value"
                        label="Regra p/ Grupo Produto"
                        color="amber darken-4"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        dark
                        rounded
                        small
                        color="red"
                        @click="cleanAtributesRules()"
                        >Limpar Regras de Atributos
                        <v-icon>mdi-trash-can</v-icon></v-btn
                      >
                    </v-col>
                    <br />
                    <br />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <hr />
              <h3>Regras</h3>
              <v-row>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    v-model="formData.rule_type"
                    :items="RuleTypeList"
                    item-text="name"
                    item-value="value"
                    label="Tipo de Regra"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
                <v-col md="2" cols="12">
                  <v-autocomplete
                    v-model="formData.rule_products_amount"
                    :items="RuleAmountProductList"
                    item-text="value"
                    item-value="value"
                    label="Produtos"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
                <v-col md="2" cols="12">
                  <v-autocomplete
                    v-model="formData.rule_apply_to"
                    :items="DiscountRuleList"
                    item-text="name"
                    item-value="value"
                    label="Aplicar Desconto Em"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <hr />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" cols="12" align="center" v-if="formData.updated_at">
              <p>
                <strong>Ultima Modificação: {{ formData.updated_at }}</strong>
              </p>
            </v-col>
            <v-col sm="6" cols="12" align="center" v-if="formData.created_at">
              <p>
                <strong>Data Cadastro: {{ formData.created_at }}</strong>
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="3" md="2" lg="1" align="center">
              <v-btn
                v-if="formData.id"
                class="change-btn"
                @click="updateContent()"
                >Salvar Alterações</v-btn
              >
              <v-btn
                v-else
                :disabled="!valid"
                class="register-btn"
                @click="saveContent()"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      RuleToProductsAtributes: [
        { value: null, name: "nenhuma" },
        { value: 0, name: "Remove produtos da Coleção" },
        { value: 1, name: "Aplica somente em produtos da Coleção" },
      ],
      RuleAmountProductList: [
        { value: null },
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
      ],
      DiscountRuleList: [
        {
          value: null,
          name: "Nenhum",
        },
        {
          value: "all",
          name: "Todos",
        },
        {
          value: "expensive",
          name: "No mais caro",
        },
        {
          value: "cheap",
          name: "No mais barato",
        },
      ],
      RuleTypeList: [
        {
          value: null,
          name: "Nenhum",
        },
        { value: "from", name: "A Partir de" },
        { value: "each", name: "A Cada" },
      ],
      adm: false,
      valid: false,
      discountType: "",
      doesNotExpire: false,
      initDateTime: "",
      expireDateTime: "",
      checkAtributes: false,
    };
  },
  components: {
    AdminBlade,
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    ...mapState("ProductGroup", {
      productGroupList: "list",
    }),
    ...mapState("Coupon", ["formData", "errors"]),
    ...mapState("Company", {
      companyList: "list",
    }),
    ...mapState("Definition", {
      definitionList: "list",
    }),
    ...mapState("Category", {
      categoryList: "list",
    }),
  },
  watch: {
    // definitionList() {
    //   if (this.definitionList[0] && !this.$route.params.id) {
    //     this.formData.definition_id = this.definitionList[0].id;
    //     this.defineCategory(this.formData.definition_id);
    //   }
    // },
    routeId() {
      this.onload();
    },
    doesNotExpire() {
      if (this.doesNotExpire) {
        this.initDate = "";
        this.initTime = "";
        this.initDateTime = "";
        this.expireDate = "";
        this.expireTime = "";
        this.expireDateTime = "";
      }
    },
    initDateTime() {
      if (this.initDateTime) {
        this.initDateTime = this.initDateTime
          .toLocaleString("ja-JP", { timeZone: "America/Bahia" })
          .slice(0, 19)
          .replace("/", "-")
          .replace("T", " ");

        this.formData.init_date = this.initDateTime;
      }
    },
    expireDateTime() {
      if (this.expireDateTime) {
        this.expireDateTime = this.expireDateTime
          .toLocaleString("ja-JP", { timeZone: "America/Bahia" })
          .slice(0, 19)
          .replace("/", "-")
          .replace("T", " ");

        this.formData.expire_date = this.expireDateTime;
      }
    },
    formData() {
      this.checkDiscountType();
      this.getDateTime();

      if (this.formData.discount_value && this.formData.discount_value > 0) {
        this.formData.discount_value = parseFloat(
          this.formData.discount_value
        ).toFixed(2);
      }
    },
    discountType() {
      switch (this.discountType) {
        case "Valor":
          this.formData.discount_rate = 0;
          break;
        case "Taxa":
          this.formData.discount_value = 0;
      }
    },
  },
  methods: {
    ...mapActions("Coupon", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("ProductGroup", {
      setProductGroupList: "setList",
    }),
    ...mapActions("Definition", {
      setDefinition: "setList",
    }),
    ...mapActions("Category", {
      setCategory: "setFilteredList",
    }),
    ...mapActions("Category", {
      setAllCategory: "setList",
    }),
    ...mapActions("Company", ["setList"]),
    saveContent() {
      if (this.formData.discount_rate == null) {
        this.formData.discount_rate = 0;
      }
      if (this.formData.discount_value == null) {
        this.formData.discount_value = 0;
      }
      this.save(this.formData);
    },
    updateContent() {
      if (this.formData.discount_rate == null) {
        this.formData.discount_rate = 0;
      }
      if (this.formData.discount_value == null) {
        this.formData.discount_value = 0;
      }
      this.update(this.formData);
    },
    getDateTime() {
      this.initDateTime = this.formData.init_date;
      this.expireDateTime = this.formData.expire_date;
    },
    checkDiscountType() {
      if (
        this.formData.discount_value &&
        this.formData.discount_value.length > 0 &&
        this.formData.discount_value != 0
      ) {
        this.discountType = "Valor";
      } else if (
        this.formData.discount_rate &&
        this.formData.discount_rate.length > 0 &&
        this.formData.discount_rate != 0
      ) {
        this.discountType = "Taxa";
      } else {
        this.discountType = "Taxa";
      }
    },
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    onload() {
      this.setDefinition();
      this.adm = JSON.parse(localStorage.getItem("user")).admin;
      if (this.adm) {
        this.setList();
      }
      // if (!this.adm) {
      this.setProductGroupList();
      // }
      if (this.$route.params.id) {
        this.setAllCategory();
        this.show(this.$route.params.id);
      }
      if (this.formData.active == null) {
        this.formData.active = true;
      }
      if (this.formData.remove_sale == null) {
        this.formData.remove_sale = false;
      }
    },
    setAtributes(value) {
      this.checkAtributes = value;
    },

    cleanAtributesRules() {
      this.formData.definition_id = null;
      this.formData.category_id = null;
      this.formData.product_group_id = null;
      this.formData.product_group_rule = null;

      this.checkAtributes = false;
    },
  },
  mounted() {
    this.onload();
    this.checkDiscountType();
    this.getDateTime();
  },
  beforeDestroy: function () {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>