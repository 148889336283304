<template>
  <span>
    <v-app id="app" v-if="user.id">
      <v-navigation-drawer
        v-model="drawer"
        width="250px"
        app
        clipped
        expand-on-hover
        class="left-nav"
      >
        <nav-left></nav-left>
      </v-navigation-drawer>

      <v-app-bar app clipped-left dense class="top-bar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title>
          <img class="logo-top" :src="logo" />
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <strong class="user-name">{{ user.name }}</strong>
        <v-menu transition="slide-x-transition">
          <template v-slot:activator="{ on }">
            <button class="option-btn-avatar mb-3" v-on="on">
              <i class="fas fa-chevron-down"></i>
            </button>
          </template>
          <v-list>
            <v-list-item @click="logout()">
              <v-list-item-title class="ml-5 mr-5">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="defautl-content">
        <!-- <v-container> -->
        <span class="buttons-topo">
          <v-btn
            v-if="iconBtnTop"
            :to="routeBtnTop"
            fab
            small
            dark
            absolute
            bottom
            right
            fixed
            class="actions-btn"
          >
            <v-icon class="actions-btn-icon">{{ iconBtnTop }}</v-icon>
          </v-btn>
        </span>
        <spinner v-show="spinnerFullScreen" />
        <slot></slot>
        <!-- </v-container> -->
      </v-main>

      <v-footer app>
        <span>Gransys &copy; {{ date }}</span>
      </v-footer>
    </v-app>
  </span>
</template>

<script>
import Spinner from "@//Layouts/Components/spinner";
import navLeft from "@//Layouts/Components/material/navLeftIntegrations";
import { mapState, mapActions } from "vuex";
import logo from "@/assets/logoText.png";

export default {
  props: {
    iconBtnTop: String,
    routeBtnTop: String,
  },
  components: {
    Spinner,
    navLeft,
  },
  computed: {
    ...mapState("Spinner", ["spinnerFullScreen"]),
    ...mapState("Auth", ["user"]),
  },
  data: () => ({
    drawer: null,
    logo: logo,
    date: null,
  }),
  methods: {
    ...mapActions("Auth", ["logout"]),

    // chevronChange(value) {
    //   if (value == false) {
    //     return "mdi-chevron-right";
    //   } else {
    //     return "mdi-chevron-down";
    //   }
    // },

    getDateYear() {
      let currentYear = new Date();
      this.date = currentYear.getFullYear();
      return this.date;
    },
  },
  mounted() {
    this.getDateYear();
  },
};
</script>
<style scoped>
.left-nav {
  background: #002b43;
}

.top-bar {
  background: #e66b25 !important;
}

.user-name {
  color: black;
}

.option-btn-avatar {
  color: black;
  padding: 15px 10px 0 10px;
}
.logo-top {
  width: 120px;
}
@media only screen and (max-width: 600px) {
  .user-name {
    font-size: 14px;
  }

  .logo-top {
    width: 70px;
    margin-left: -20px !important;
  }
}
</style>