<template>
  <span>
    <!-- Título -->
    <v-row>
      <v-col cols="12">
        <h2 class="form-title">Formulário de Endereço</h2>
      </v-col>
    </v-row>

    <!-- Formulário de Endereço -->
    <v-card class="form-container elevation-2">
      <v-row>
        <!-- CEP -->
        <v-col sm="6" md="6" cols="12">
          <v-text-field
            v-model="formData.cep"
            v-mask="'#####-###'"
            :rules="rulesFunction('Cep', 8)"
            @keyup="searchCep()"
            label="CEP *"
            outlined
            dense
          ></v-text-field>
          <strong class="error-text">{{ errors.cep }}</strong>
        </v-col>

                  <!-- Estado -->

        <v-col sm="6" md="6" cols="12">
          <v-autocomplete
            v-model="formData.state"
            :items="BrazilStates"
            :rules="rulesFunction('Estado')"
            item-text="name"
            item-value="value"
            label="Estado"
            color="amber darken-4"
            outlined
            dense
          ></v-autocomplete>
          <strong class="error-text">{{ errors.state }}</strong>
        </v-col>

        <!-- Cidade -->
        <v-col sm="6" md="6" cols="12">
          <v-text-field
            v-model="formData.city"
            :rules="rulesFunction('Cidade', 2)"
            label="Cidade *"
            outlined
            dense
          ></v-text-field>
          <strong class="error-text">{{ errors.city }}</strong>
        </v-col>

        <!-- Bairro -->
        <v-col sm="6" md="6" cols="12">
          <v-text-field
            v-model="formData.neighborhood"
            :rules="rulesFunction('Bairro', 2)"
            label="Bairro *"
            outlined
            dense
          ></v-text-field>
          <strong class="error-text">{{ errors.neighborhood }}</strong>
        </v-col>
      </v-row>

      <v-row>
        <!-- Endereço -->
        <v-col sm="6" md="6" cols="12">
          <v-text-field
            v-model="formData.street"
            :rules="rulesFunction('Rua')"
            label="Endereço *"
            outlined
            dense
          ></v-text-field>
          <strong class="error-text">{{ errors.street }}</strong>
        </v-col>

        <!-- Número -->
        <v-col sm="3" md="3" cols="12">
          <v-text-field
            v-model="formData.number"
            :rules="rulesFunction('Número')"
            label="Número *"
            type="number"
            outlined
            dense
          ></v-text-field>
          <strong class="error-text">{{ errors.number }}</strong>
        </v-col>

        <v-col sm="3" md="3" cols="12">
          <v-switch
            color="amber darken-4"
            v-model="formData.active"
            label="Ativo"
            inset
          ></v-switch>
        </v-col>
      </v-row>

        <!-- Switch Ativo -->
        <v-row v-if="formData.id" class="mt-4">
        
        </v-row>

      <v-row>
        <!-- Observações -->
        <v-col cols="12">
          <v-textarea
            v-model="formData.note"
            label="Observações"
            outlined
            dense
          ></v-textarea>
        </v-col>
      </v-row>

    
    </v-card>

    <!-- Botões de Ação -->
    <v-row justify="center" class="mt-4">
      <v-col md="6" cols="12">
        <v-btn
        v-if="formData.id"
        @click="update(formData)"
        class="action-btn"
        color="green darken-2"
        outlined
      >
        Salvar Alteração
      </v-btn>
      <v-btn
        v-else
        @click="saveAdress()"
        class="action-btn"
        color="green darken-2"
        outlined
      >
        Salvar
      </v-btn>

      </v-col>
    
      <v-col md="6" cols="12">
        <v-btn
        @click="setAdressDialog(false), cleanAdressItem()"
        class="action-btn"
        color="red darken-2"
        outlined
      >
        Fechar
      </v-btn>

      </v-col>
    
    </v-row>
  </span>
</template>


<script>
import { mapState, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { rulesValidationFunction } from "@/utils/rulesFunctions";

export default {
  directives: { mask },
  props: {
    companyId: Number
  },

  data: function() {
    return {};
  },
  computed: {
    ...mapState("Adress", ["formData"]),
    ...mapState("Adress", ["BrazilStates"]),
    ...mapState("Adress", ["errors"])
  },
  methods: {
    ...mapActions("Adress", ["setAdressIfCep"]),
    ...mapActions("Adress", ["cleanAdressItem"]),
    ...mapActions("Adress", ["setAdressDialog"]),
    ...mapActions("Adress", ["save"]),
    ...mapActions("Adress", ["update"]),

    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    saveAdress() {
      let data = {
        company_id: this.companyId,
        ...this.formData
      };
      this.save(data);
    },

    searchCep() {
      if (this.formData.cep.length == 9) {
        let data = {};

        this.$viaCep.buscarCep(this.formData.cep).then(obj => {
          (data.city = obj.localidade),
            (data.state = obj.uf),
            (data.street = obj.logradouro),
            (data.neighborhood = obj.bairro),
            (data.note = this.formData.note),
            (data.number = this.formData.number),
            (data.cep = this.formData.cep),
            this.setAdressIfCep(data);
        });
      }
    }
  }
};
</script>

<style scoped>
.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.form-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.error-text {
  color: #e53935;
  font-size: 12px;
}

.action-btn {
  text-transform: uppercase;
  font-weight: bold;
  margin: 5px;
}

.action-btn:hover {
  opacity: 0.9;
}

</style>