<template>
  <span>
    <!-- Título -->
    <v-row>
      <v-col cols="12">
        <h1 class="form-title">Formulário de Telefone</h1>
      </v-col>
    </v-row>

    <!-- Campos do Formulário -->
    <v-card class="form-container elevation-2">
      <v-row>
        <!-- Tipo -->
        <v-col sm="3" cols="12">
          <v-select
            v-model="formData.type"
            label="Tipo"
            :items="listTypes"
            item-text="name"
            item-value="value"
            outlined
            dense
          ></v-select>
          <strong class="error-text">{{ errors.type }}</strong>
        </v-col>

        <!-- Número -->
        <v-col sm="5" cols="12">
          <v-text-field
            v-model="formData.number"
            v-mask="['(##) ####-####', '(##) #.####-####']"
            label="Número"
            :rules="rulesFunction('Número', 13)"
            outlined
            dense
          ></v-text-field>
          <strong class="error-text">{{ errors.number }}</strong>
        </v-col>

        <!-- Observação -->
        <v-col sm="4" cols="12">
          <v-textarea
            rows="1"
            v-model="formData.note"
            label="Observação"
            outlined
            dense
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Switch Ativo -->
      <v-row v-if="formData.id" class="mt-4">
        <v-col cols="12">
          <v-switch
            color="amber darken-4"
            v-model="formData.active"
            label="Ativo"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </v-card>

    <!-- Botões -->
    <v-row justify="center" class="mt-4">
      <v-col md="6" cols="12">
        <v-btn
        v-if="formData.id"
        class="action-btn"
        @click="updatePhone()"
        color="green darken-2"
        outlined
      >
        Salvar Alteração
      </v-btn>
      <v-btn
        v-else
        class="action-btn"
        @click="savePhone()"
        color="green darken-2"
        outlined
      >
        Salvar
      </v-btn>

      </v-col>

      <v-col md="6" cols="12">
        <v-btn
        class="action-btn"
        @click="setPhoneDialog(false), cleanPhoneItem()"
        color="red darken-2"
        outlined
      >
        Fechar
      </v-btn>

      </v-col>
   
     
    </v-row>
  </span>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mask } from "vue-the-mask";
import { removeOverCaractersForNumbers } from "@/utils/masksFunctions";

export default {
  data: function() {
    return {};
  },
  directives: { mask },
  props: {
    company_id: Number
  },
  computed: {
    ...mapState("Phone", ["formData"]),
    ...mapState("Phone", ["errors"]),
    ...mapState("Phone", ["listTypes"])
  },
  methods: {
    ...mapActions("Phone", [
      "setPhoneDialog",
      "save",
      "update",
      "cleanPhoneItem"
    ]),

    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    savePhone() {
      this.formData.number = removeOverCaractersForNumbers(
        this.formData.number
      );
      this.formData.company_id = this.company_id;
      this.save(this.formData);
    },
    updatePhone() {
      this.formData.number = removeOverCaractersForNumbers(
        this.formData.number
      );
      this.update(this.formData);
    }
  }
};
</script>

<style>
.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.form-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.error-text {
  color: #e53935;
  font-size: 12px;
}

.action-btn {
  text-transform: uppercase;
  font-weight: bold;
  margin: 5px;
}

.action-btn:hover {
  opacity: 0.9;
}

</style>